import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, EMPTY, switchMap, timer } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

import { DurationInMilliseconds } from '@stsm/date/enums/duration-in-milliseconds';
import { DevtoolsActionLabel, DevtoolsService } from '@stsm/devtools/services/devtools.service';
import { isFullMatch } from '@stsm/shared/util/regex.util';
import { webReleaseTagRegex } from '@stsm/shared/util/regex-patterns';

import { environment } from '../../../environments/environment';
import { AppSettings } from '../../app-settings';

import { UpdateDialogService } from './update-dialog/update-dialog.service';

const INTERVAL = DurationInMilliseconds.HOUR;

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-update',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateComponent implements OnDestroy {
  version: string = AppSettings.VERSION_NUMBER;

  constructor(
    private readonly _updateDialogService: UpdateDialogService,
    private readonly _devtoolsService: DevtoolsService,
  ) {
    // exclude release env
    if (environment.production && isFullMatch({ value: AppSettings.VERSION_NUMBER, regex: webReleaseTagRegex() })) {
      timer(30_000, INTERVAL)
        .pipe(
          switchMap(() =>
            fromFetch('version.json').pipe(
              switchMap((response: Response) => {
                if (response.ok) {
                  return response.json();
                }

                return EMPTY;
              }),
              catchError(() => EMPTY),
            ),
          ),
          untilDestroyed(this),
        )
        .subscribe((result: { version: string }) => {
          if (result.version !== this.version) {
            this._updateDialogService.open();
          }
        });
    }

    this._devtoolsService.registerAdditionalAction({
      label: DevtoolsActionLabel.REFRESH_DIALOG,
      action: () => {
        this._devtoolsService.closeDevtools();
        this._updateDialogService.open();
      },
    });
  }

  ngOnDestroy(): void {
    this._devtoolsService.unregisterAdditionalAction(DevtoolsActionLabel.REFRESH_DIALOG);
  }
}
