import { Injectable } from '@angular/core';

import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { SharingDialogComponent } from '../components/sharing-dialog/sharing-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SharingDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  openSharingDialog(): void {
    this._platformModalService.create({
      component: SharingDialogComponent,
      webOptions: {
        maxWidth: '800px',
      },
    });
  }
}
