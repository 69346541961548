import { Inject, Injectable, Optional } from '@angular/core';
import { defer, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { OfflineError, OfflineErrorType } from '@stsm/offline-mode/models/offline-error';
import { STUDYGROUPS_REPOSITORY, StudygroupsRepositoryInterface } from '@stsm/offline-mode/tokens/studygroups-repository.token';
import { BaseService } from '@stsm/shared/services/base.service';
import { NetworkService } from '@stsm/shared/services/network.service';
import { JsonObjectValue } from '@stsm/shared/types/json-object';
import { switchToVoid, VOID } from '@stsm/shared/util/rxjs.util';
import { Studygroup, StudygroupSerializer } from '@stsm/studygroups/models/studygroup';
import { StudygroupUpdateBuilder } from '@stsm/studygroups/models/studygroup-update-builder';
import { Studyset, StudysetSerializer } from '@stsm/studysets/models/studyset';

import { StudygroupsStoreFacade } from './store/studygroups-store-facade.service';

@Injectable({
  providedIn: 'root',
})
export class StudygroupsService {
  constructor(
    private readonly _networkService: NetworkService,
    @Optional() @Inject(STUDYGROUPS_REPOSITORY) private readonly _studygroupsRepository: StudygroupsRepositoryInterface,
    private readonly _studygroupsStoreFacade: StudygroupsStoreFacade,
    private readonly _baseService: BaseService,
    private readonly _authStore: AuthStore,
  ) {}

  fetchStudygroup(studyset: Studyset): Observable<void> {
    return defer(() => {
      if (this._networkService.isConnected()) {
        return this._baseService
          .get(`studysets/${studyset.id}/studygroup/${studyset.studygroupId}/`)
          .pipe(map(StudygroupSerializer.fromJson));
      } else {
        return this._studygroupsRepository.getStudygroup(studyset.studygroupId);
      }
    }).pipe(
      tap((studygroup: Studygroup) => {
        this._studygroupsStoreFacade.setStudygroup(studygroup);
      }),
      switchMap(() => VOID),
    );
  }

  getSuggestedEmails(): Observable<string[]> {
    return this._networkService.isConnected()
      ? this._baseService
          .get(`users/${this._authStore.userId}/proposed-studygroup-emails/`)
          .pipe(map((res: JsonObjectValue) => res as string[]))
      : of([]);
  }

  sendInvite(studyset: Studyset, email: string): Observable<object> {
    if (this._networkService.isConnected()) {
      return this._baseService.post(`studysets/${studyset.id}/studygroup/${studyset.studygroupId}/invite/`, JSON.stringify({ email }));
    } else {
      return throwError(() => new OfflineError(OfflineErrorType.INTERNET_CONNECTION_REQUIRED));
    }
  }

  updateStudygroup(studyset: Studyset, builder: StudygroupUpdateBuilder): Observable<Studygroup> {
    if (this._networkService.isConnected()) {
      return this._baseService
        .patch(`studysets/${studyset.id}/studygroup/${studyset.studygroupId}/`, JSON.stringify(builder.getBackendBody()))
        .pipe(map(StudygroupSerializer.fromJson));
      // Note: The potential studygroup in the database is updated via the onStudygroupUpdated subscription
    } else {
      return throwError(() => new OfflineError(OfflineErrorType.INTERNET_CONNECTION_REQUIRED));
    }
  }

  removeMember(studyset: Studyset, memberId: number): Observable<void> {
    if (this._networkService.isConnected()) {
      return this._baseService
        .delete(`studysets/${studyset.id}/studygroup/${studyset.studygroupId}/members/${memberId}/`, '')
        .pipe(switchToVoid());
    } else {
      return throwError(() => new OfflineError(OfflineErrorType.INTERNET_CONNECTION_REQUIRED));
    }
  }

  removeInvite(studyset: Studyset, email: string): Observable<object> {
    if (this._networkService.isConnected()) {
      return this._baseService.post(
        `studysets/${studyset.id}/studygroup/${studyset.studygroupId}/remove-invite/`,
        JSON.stringify({ email }),
      );
    } else {
      return throwError(() => new OfflineError(OfflineErrorType.INTERNET_CONNECTION_REQUIRED));
    }
  }

  joinStudygroup(studysetId: number, token: string): Observable<Studyset> {
    if (!this._networkService.isConnected()) {
      return throwError(() => new OfflineError(OfflineErrorType.INTERNET_CONNECTION_REQUIRED));
    }

    return this._baseService
      .post(`studysets/${studysetId}/join-studygroup/`, JSON.stringify({ token }))
      .pipe(map(StudysetSerializer.fromJson));
  }
}
