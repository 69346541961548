import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import * as UserActions from '@stsm/user/store/user.actions';

import * as StudyplanActions from './studyplan.actions';

@Injectable()
export class StudyplanEffects {
  removeEventsOnLogout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.logout),
      map(() => StudyplanActions.clearEvents()),
    ),
  );

  constructor(private readonly _actions$: Actions) {}
}
