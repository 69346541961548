import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler extends SentryErrorHandler {
  // The constructor is necessary
  constructor() {
    super();
  }

  override handleError(error: unknown): void {
    // Call Sentry's error handler to capture errors
    super.handleError(error);
  }
}
