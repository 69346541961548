import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { isNil } from 'lodash-es';
import { switchMap, take, tap } from 'rxjs';

import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { LoggerLevel } from '@stsm/shared/logger/logger';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

@Injectable({ providedIn: 'root' })
export class TroubleshootingModeService {
  constructor(
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _loggerService: LoggerService,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _userStoreFacade: UserStoreFacade,
  ) {}

  checkForTroubleshootingMode(): void {
    this._userStoreFacade.userAvailable$
      .pipe(
        take(1),
        switchMap((user: User) => {
          if (!user.troubleshootingActive) {
            // always remove any potentially existing consent if the troubleshooting timeframe ended
            this._browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.TROUBLESHOOTING_CONSENT);

            return VOID;
          }

          const hasConsent = !isNil(this._browserStorageService.getItemLocalStorage(GlobalLocalStorageKey.TROUBLESHOOTING_CONSENT));

          // helper inline function
          const startRecording = (): void => {
            this._loggerService.loggerLevel = LoggerLevel.DEBUG;
            this._loggerService.debug('START RECORDING');
            const replay = Sentry.getReplay();

            if (isNil(replay)) {
              this._loggerService.warn('Sentry Replay is not defined');

              return;
            }

            try {
              replay?.start();
              Sentry.setUser({ id: user.id, email: user.appUser.email });
            } catch (e: unknown) {
              this._loggerService.warn(e);
            }
          };

          if (hasConsent) {
            startRecording();

            return VOID;
          }

          const dialogRef = this._simpleDialogService.confirm({
            text: 'TROUBLESHOOTING.CONSENT',
          });

          return dialogRef.afterClosed().pipe(
            tap((isConfirmed: boolean) => {
              if (isConfirmed) {
                this._browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.TROUBLESHOOTING_CONSENT, true);
                startRecording();
              }
            }),
          );
        }),
      )
      .subscribe();
  }
}
