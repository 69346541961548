import FroalaEditor from 'froala-editor';
import { isNil } from 'lodash-es';

const IMAGE_SIZES = ['thumbnail', 'medium', 'large', 'full'] as const;

type ImageSize = (typeof IMAGE_SIZES)[number];

const IMAGE_SIZES_VALUES = {
  thumbnail: '150px',
  medium: '300px',
  large: '1024px',
};

FroalaEditor.DefineIcon('customImageSize', { NAME: 'arrows-alt', SVG_KEY: 'imageSize' });
FroalaEditor.RegisterCommand('customImageSize', {
  title: 'Image size',
  type: 'dropdown',
  focus: false,
  undo: false,
  refreshAfterCallback: true,
  options: {
    thumbnail: 'Thumbnail: 150 x 150',
    medium: 'Medium: 300 x 300',
    large: 'Large: 1024 x 1024',
    full: 'Full size',
  },
  // eslint-disable-next-line
  callback: function (_: string, value: ImageSize) {
    const imagesRef = this.image.get() as JQuery<HTMLImageElement>;
    const imageRef = imagesRef[0];

    if (isNil(imageRef)) {
      return;
    }

    IMAGE_SIZES.forEach((size: string) => {
      imageRef.classList.remove(`fr-size-${size}`);
    });

    if (value !== 'full') {
      if (imageRef.width - imageRef.height >= 0) {
        this.image.setSize(IMAGE_SIZES_VALUES[value], 'auto');
      } else {
        this.image.setSize('auto', IMAGE_SIZES_VALUES[value]);
      }
    } else {
      this.image.setSize('auto', 'auto');
    }

    // Currently styles are not applied to this class name. We use it only for recognising previously selected size
    imageRef.classList.add(`fr-size-${value}`);
  },
  // eslint-disable-next-line
  refreshOnShow: function (_: HTMLElement[], $dropdown: HTMLElement[]) {
    const imagesRef = this.image.get() as JQuery<HTMLImageElement>;
    const imageRef = imagesRef[0];

    if (isNil(imageRef)) {
      return;
    }

    IMAGE_SIZES.forEach((size: string) => {
      if (imageRef.classList.contains(`fr-size-${size}`)) {
        const activeOption = $dropdown[0]?.querySelectorAll('.fr-active');

        if (!isNil(activeOption) && !isNil(activeOption[0])) {
          activeOption[0].classList.remove('fr-active');
          activeOption[0].setAttribute('aria-selected', 'false');
        }

        const selectedOption = $dropdown[0]?.querySelectorAll(`[data-param1="${size}"]`);

        if (!isNil(selectedOption) && !isNil(selectedOption[0])) {
          selectedOption[0].classList.add('fr-active');
          selectedOption[0].setAttribute('aria-selected', 'true');
        }
      }
    });
  },
});
