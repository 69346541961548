<div class="image-container">
  <img alt="" [src]="fileTypeIcon" />
</div>
<div class="content">
  <div class="heading">
    <div class="text-sm-semibold one-liner">{{ fileName }}</div>
    <ui-checkbox [isChecked]="isChecked" [isDisabled]="isDisabled" (valueChange)="valueChange.emit($event)" />
  </div>
  <p *ngIf="updatedAt" class="text-sm-regular">{{ updatedAt | localeDateString }}</p>
</div>
