import { InjectionToken } from '@angular/core';

import { Studygroup } from '@stsm/studygroups/models/studygroup';

export interface StudygroupsRepositoryInterface {
  getStudygroup(studygroupId: number): Promise<Studygroup>;
}

export const STUDYGROUPS_REPOSITORY: InjectionToken<StudygroupsRepositoryInterface> = new InjectionToken<StudygroupsRepositoryInterface>(
  'StudygroupsRepository',
);
