<ng-container *ngIf="!showDownloadConfig">
  <span class="title"><i class="app-icon-language"></i></span>
  <span class="key-toggle">
    <input type="checkbox" id="showKeys" class="cursor-pointer" [formControl]="showKeysControl" />
    <label for="showKeys" class="cursor-pointer">Show Keys</label>
    <i
      class="app-icon-information-circle"
      title="Keys in Lokalise have two colons instead of the dot, e.g. 'GLOBAL.OK' becomes 'GLOBAL::OK'"
    ></i>
  </span>
  <ng-container *ngIf="showAdvancedFeatures">
    <div class="lokalise-button-container">
      <i *ngIf="errorDuringDownload" class="app-icon-flash"></i>
      <button class="lokalise-button" [disabled]="isLoading" (click)="getUpdatedFiles()">
        <i *ngIf="!isLoading; else lokaliseLoading" class="app-icon-cloud-download"></i>
        <ng-template #lokaliseLoading>
          <div>loading...</div>
        </ng-template>
        Lokalise
      </button>
      <i class="app-icon-settings" (click)="showDownloadConfig = true"></i>
    </div>

    <span class="pupil-toggle">
      <input
        type="checkbox"
        id="isPupil"
        class="cursor-pointer"
        [formControl]="isPupilControl"
        [attr.disabled]="currentLanguage === 'de' ? null : true"
      />
      <label for="isPupil" class="cursor-pointer">Is Pupil</label>
    </span>
  </ng-container>

  <div class="language-select-area">
    <select [formControl]="languageControl">
      <option *ngFor="let language of supportedLanguages" [value]="language">{{ language }}</option>
    </select>
    x
    <input class="multiplication-select" type="number" [formControl]="multiplyFactorControl" [min]="1" [max]="99" />
  </div>
</ng-container>

<ng-container *ngIf="showDownloadConfig">
  <i class="app-icon-settings title"></i>
  <span class="branch-select">
    <span>Branch: </span>
    <select [formControl]="branchControl">
      <option *ngFor="let branch of branches" [value]="branch">{{ branch }}</option>
    </select>
  </span>
  <span class="all-languages-toggle">
    <input type="checkbox" id="downloadLanguages" class="cursor-pointer" [formControl]="languageDownloadControl" />
    <label for="downloadLanguages" class="cursor-pointer">Download all languages</label>
  </span>
  <i class="app-icon-check close-button" (click)="showDownloadConfig = false"></i>
</ng-container>
