import type { WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChatGptPromptService, PROMPT_MAP } from '@stsm/global/composite/services/chat-gpt-prompt.service';
import { PromptTemplateId } from '@stsm/global/models/ai/ai-prompt-template';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { ButtonComponent } from '@stsm/ui-components/button';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { OptionComponent } from '@stsm/ui-components/option';
import { SelectComponent } from '@stsm/ui-components/select';

@Component({
  selector: 'app-prompt-dialog',
  standalone: true,
  imports: [FormsModule, ButtonComponent, DialogTemplateComponent, ReactiveFormsModule, SelectComponent, OptionComponent],
  templateUrl: './prompt-dialog.component.html',
  styleUrl: './prompt-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptDialogComponent {
  promptTemplate: WritableSignal<string> = signal('');

  prompts: { promptId: PromptTemplateId; content: string }[] = Object.entries(PROMPT_MAP).map(([promptId, content]: [string, string]) => ({
    promptId: promptId as PromptTemplateId,
    content,
  }));

  protected readonly formControl: FormControl<PromptTemplateId> = new FormControl<PromptTemplateId>('assistant_notification', {
    nonNullable: true,
  });

  constructor(
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _chatGptPromptService: ChatGptPromptService,
    private readonly _toastService: ToastService,
  ) {}

  selectPrompt(promptId: PromptTemplateId): void {
    this.promptTemplate.set(this._browserStorageService.getItemLocalStorage(promptId) || PROMPT_MAP[promptId]);
  }

  savePrompt(): void {
    this._toastService.successToast('Saved prompt template changes');
    this._browserStorageService.setItemLocalStorage(this.formControl.value, this.promptTemplate());
  }

  clearPrompt(): void {
    this.promptTemplate.set('');
    this._browserStorageService.setItemLocalStorage(this.formControl.value, '');
    this._toastService.successToast('Cleared prompt template from local storage');
  }

  setPromptDefaults(): void {
    this._chatGptPromptService.setPromptDefaults();
    this.selectPrompt(this.formControl.value);
  }
}
