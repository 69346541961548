import { Injectable } from '@angular/core';

import { NotificationsPermissionState } from '@stsm/global/models/notifications-permission-state';

import { NotificationsBaseService } from './notifications-base.service';

@Injectable({ providedIn: 'root' })
export class NotificationsService extends NotificationsBaseService {
  checkPermissions(): Promise<NotificationsPermissionState> {
    return Promise.resolve('denied');
  }

  enableDeviceNotifications(_enable: boolean): Promise<NotificationsPermissionState | undefined> {
    return Promise.resolve(undefined);
  }
}
