import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { Studygroup } from '@stsm/studygroups/models/studygroup';
import { ShareStudysetBaseService } from '@stsm/studysets/feature/services/share-studyset-base.service';
import { Studyset } from '@stsm/studysets/models/studyset';

@Injectable({ providedIn: 'root' })
export class ShareStudysetService extends ShareStudysetBaseService {
  async shareStudysetLink(studyset: Studyset): Promise<void> {
    const user = await firstValueFrom(this.userStoreFacade.user$);
    await navigator.clipboard.writeText(this.getLinkForStudyset(studyset, user));
    this.toastService.successToast('SHARE.STUDYSET_LINK_COPIED');
  }

  protected async shareStudygroupLink(studyset: Studyset, studygroup: Studygroup): Promise<void> {
    const user = await firstValueFrom(this.userStoreFacade.user$);
    await navigator.clipboard.writeText(this.getLinkForStudygroup(studyset, studygroup, user));
    this.toastService.successToast('SHARE.STUDYSET_LINK_COPIED');
  }
}
