import { Injectable } from '@angular/core';
import { difference, isFunction, last } from 'lodash-es';
import { finalize, forkJoin, map, Observable, of, take, tap } from 'rxjs';

import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { User } from '@stsm/user/models/user';

import { GlobalMessageService, MobileAppHasUpdatedInfo } from '../../services/global-message.service';

import { WhatsNewModalComponent, WhatsNewModalProps, WhatsNewSlideData } from './whats-new-modal.component';

/**
 * saved in LocalStorage when the user has seen a What's New Modal --> {@link GlobalLocalStorageKey.SEEN_WHATS_NEW_IDS}
 *
 * Previous keys:
 * SPACED_REPETITION = 'SPACED_REPETITION',
 * SMART_EXAM = 'SMART_EXAM',
 * TEXT_TO_SPEECH = 'TEXT_TO_SPEECH'
 * MERGE_EXAM_AND_QUIZ = 'MERGE_EXAM_AND_QUIZ',
 * VAIA = 'VAIA',
 * REDESIGN = 'REDESIGN',
 * MAGIC_MARKER = 'MAGIC_MARKER',
 * AI_MULTIPLE_CHOICE = 'AI_MULTIPLE_CHOICE',
 */
export enum WhatsNewId {
  AI_MULTIPLE_CHOICE = 'AI_MULTIPLE_CHOICE',
}

export interface WhatsNewConfig {
  slides: WhatsNewSlideData[];
  titleTranslationKey?: string;
  confirmButton?: boolean;
  confirmTranslationKey?: string;
  dismissTranslationKey?: string;
  modalCssClasses?: string[];
  showToUser?: (user: User) => Observable<boolean>;
  onConfirmClick?: () => void;
}

interface ReleaseUpdateVisibility {
  whatsNewId: WhatsNewId;
  shouldShowInDialog: boolean;
}

/**
 * Service for managing the display of "What's New" dialogs to the user when there is a new feature release.
 * Instructions:
 * 1. Add a new WhatsNewId to the WhatsNewId enum.
 * 2. Add a new WhatsNewConfig to the _currentDialogs object.
 * 3. Optional: Add a new WhatsNewSlideData to the slides array in the WhatsNewConfig.
 */
@Injectable({
  providedIn: 'root',
})
export class WhatsNewModalService {
  private readonly _currentDialogs: { [K in WhatsNewId]: WhatsNewConfig } = {
    [WhatsNewId.AI_MULTIPLE_CHOICE]: {
      slides: [
        {
          illustrationName: 'ai-mc-test',
          subtitleTranslationKey: 'WHATS_NEW.AI_MULTIPLE_CHOICE.TITLE',
          textTranslationKey: 'WHATS_NEW.AI_MULTIPLE_CHOICE.SUBTITLE',
          trackingFeature: 'ai_multiple_choice',
        },
      ],
      confirmTranslationKey: 'WHATS_NEW.AI_MULTIPLE_CHOICE.CONFIRM',
      onConfirmClick: () => {
        this._globalMessageService.sendReleaseNoteConfirmClick(WhatsNewId.AI_MULTIPLE_CHOICE);
      },
    },
  };

  constructor(
    private readonly _platformModalService: PlatformModalService,
    private readonly _globalMessageService: GlobalMessageService,
  ) {}

  /**
   * Retrieves the list of WhatsNewId values that the user has already seen.
   *
   * @param whatsNewIds - An array of WhatsNewId to display in the modal.
   * @param appHasUpdatedInfo - Information about the app update status.
   * @param user - The User object for whom the modal is being shown.
   * @returns {Observable<WhatsNewId[]>} An Observable that emits an array of WhatsNewId values representing the "What's New" dialogs the user has seen.
   */
  getVisibleWhatsNewIds(
    whatsNewIds: WhatsNewId[],
    appHasUpdatedInfo: MobileAppHasUpdatedInfo | 'webapp',
    user: User,
  ): Observable<WhatsNewId[]> {
    const dialogConfig: WhatsNewConfig[] = whatsNewIds.map((id: WhatsNewId): WhatsNewConfig => this._currentDialogs[id]);

    if (dialogConfig.length === 0) {
      return of([]);
    }

    const unseenWhatsNewIds: WhatsNewId[] = difference(whatsNewIds, this._getSeenWhatsNewIds());

    if (unseenWhatsNewIds.length === 0) {
      return of([]);
    }

    return forkJoin(
      unseenWhatsNewIds.map((whatsNewId: WhatsNewId): Observable<ReleaseUpdateVisibility> => {
        const dialogConfig: WhatsNewConfig = this._currentDialogs[whatsNewId];
        const isDialogEnabledForUser$: Observable<boolean> = isFunction(dialogConfig.showToUser) ? dialogConfig.showToUser(user) : of(true);

        return isDialogEnabledForUser$.pipe(
          take(1),
          map((isDialogEnabledForUser: boolean): ReleaseUpdateVisibility => {
            let shouldShowInDialog: boolean = isDialogEnabledForUser;

            if ((appHasUpdatedInfo !== 'webapp' && !appHasUpdatedInfo.hasAppUpdated) || !user.returningUser) {
              // new install --> user shouldn't see dialog, pretend they've seen it
              this._addToSeenWhatsNewIds(whatsNewId);
              shouldShowInDialog = false;
            }

            return {
              shouldShowInDialog,
              whatsNewId,
            };
          }),
        );
      }),
    ).pipe(
      map((releaseUpdates: ReleaseUpdateVisibility[]): WhatsNewId[] => {
        return releaseUpdates
          .filter(({ shouldShowInDialog }: ReleaseUpdateVisibility) => shouldShowInDialog)
          .map(({ whatsNewId }: ReleaseUpdateVisibility) => whatsNewId);
      }),
    );
  }

  /**
   * Shows the What's New modal for the given What's New IDs and user.
   *
   * @param whatsNewIds - An array of WhatsNewId to display in the modal.
   * @param user - The User object for whom the modal is being shown.
   * @returns {Observable<boolean | undefined>} An Observable that emits a boolean (if the modal was confirmed or dismissed) or undefined (if no modal was shown).
   */
  showModal(whatsNewIds: WhatsNewId[], user: User): Observable<boolean | undefined> {
    const dialogConfig: WhatsNewConfig[] = whatsNewIds.map((id: WhatsNewId): WhatsNewConfig => this._currentDialogs[id]);

    if (dialogConfig.length === 0) {
      return of(undefined);
    }

    const filteredSlides: WhatsNewSlideData[] = this._getFilteredSlides(dialogConfig, user);

    if (filteredSlides.length === 0) {
      return of(undefined);
    }

    return this._scheduleWhatsNewModal(dialogConfig, filteredSlides, whatsNewIds);
  }

  /**
   * Schedules and displays the What's New modal with the given configuration.
   *
   * @param dialogConfig - The configuration for the What's New modal.
   * @param filteredSlides - The slides to be displayed in the modal.
   * @param whatsNewIds - The IDs of the What's New items being displayed.
   * @returns {Observable<boolean | undefined>} An Observable that emits a boolean indicating whether the modal was confirmed or dismissed.
   */
  private _scheduleWhatsNewModal(
    dialogConfig: WhatsNewConfig[],
    filteredSlides: WhatsNewSlideData[],
    whatsNewIds: WhatsNewId[],
  ): Observable<boolean | undefined> {
    const { confirmButton, confirmTranslationKey, dismissTranslationKey, titleTranslationKey, modalCssClasses, onConfirmClick } =
      last(dialogConfig) ?? {};

    return this._platformModalService
      .schedule<WhatsNewModalProps, boolean>({
        component: WhatsNewModalComponent,
        data: {
          slides: filteredSlides,
          confirmButton,
          confirmTranslationKey,
          dismissTranslationKey,
          titleTranslationKey,
        },
        webOptions: {
          width: '648px',
          maxWidth: '90%',
        },
        mobileOptions: {
          isFullscreen: true,
          shouldApplySafeAreaTop: false,
        },
        cssClasses: modalCssClasses,
        disposeOnNavigation: false,
      })
      .pipe(
        tap((confirmed: boolean | undefined) => {
          if (confirmed && onConfirmClick) {
            onConfirmClick();
          }
        }),
        finalize(() => {
          whatsNewIds.forEach((whatsNewId: WhatsNewId) => this._addToSeenWhatsNewIds(whatsNewId));
        }),
      );
  }

  /**
   * Filters and flattens the slides from the given dialog configurations based on user visibility.
   *
   * @param dialogConfigs - An array of WhatsNewConfig objects containing slide configurations.
   * @param user - The User object to check slide visibility against.
   * @returns {WhatsNewSlideData[]} An array of WhatsNewSlideData objects that are visible to the user.
   */
  private _getFilteredSlides(dialogConfigs: WhatsNewConfig[], user: User): WhatsNewSlideData[] {
    return dialogConfigs
      .map((config: WhatsNewConfig) => config.slides)
      .flat()
      .filter((slide: WhatsNewSlideData) => !slide.showToUser || slide.showToUser(user));
  }

  private _getSeenWhatsNewIds(): WhatsNewId[] {
    return JSON.parse(localStorage.getItem(GlobalLocalStorageKey.SEEN_WHATS_NEW_IDS) ?? '[]');
  }

  private _addToSeenWhatsNewIds(configKey: WhatsNewId): void {
    localStorage.setItem(GlobalLocalStorageKey.SEEN_WHATS_NEW_IDS, JSON.stringify([...this._getSeenWhatsNewIds(), configKey]));
  }
}
