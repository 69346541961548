import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { SentryService } from '@stsm/shared/services/sentry.service';

/**
 * global interceptor to block backend requests which include undefined, null or NaN in the url
 */
@Injectable()
export class ValidationInterceptor implements HttpInterceptor {
  constructor(private readonly _sentryService: SentryService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (/\/(undefined|null|NaN)\//.test(req.url)) {
      const errorMessage = `Invalid backend request: ${req.method} ${req.urlWithParams}`;
      this._sentryService.reportToSentry(errorMessage);

      return throwError(() => new Error(errorMessage));
    }

    return next.handle(req);
  }
}
