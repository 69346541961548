<ng-container *ngIf="ad">
  <header>
    <span>{{ ad.companyName }}</span>

    <button uiIconButton tertiary (click)="dismiss()">
      <i class="app-icon-close"></i>
    </button>
  </header>

  <main>
    <img uiIllustration="error" alt="" role="presentation" />

    <h5>{{ "LEAD_GEN_ADS.SUBMIT_ERROR_HEADLINE" | translate }}</h5>

    <p>{{ "LEAD_GEN_ADS.SUBMIT_ERROR_TEXT" | translate }}</p>
  </main>

  <footer>
    <button uiButton (click)="dismiss()">
      {{ "LEAD_GEN_ADS.SUBMIT_ERROR_CONFIRM_BUTTON" | translate }}
    </button>
  </footer>
</ng-container>
