import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';

import { CreateEditWorkExperienceComponent } from './create-edit-work-experience.component';

export const unsavedWorkExperienceGuard: CanDeactivateFn<CreateEditWorkExperienceComponent> = (
  component: CreateEditWorkExperienceComponent,
): Observable<boolean> | boolean => {
  const hasUnsavedChanges = component.hasUnsavedChanges();

  if (!hasUnsavedChanges) {
    return true;
  }

  const simpleDialogService = inject(SimpleDialogService);

  return simpleDialogService
    .confirm({
      heading: 'PROFILE.WORK_EXPERIENCE.DISCARD_CHANGES_HEADING',
      subheading: 'PROFILE.WORK_EXPERIENCE.DISCARD_CHANGES_MESSAGE',
      isDestructive: true,
      confirmText: 'GLOBAL.DISCARD_CHANGES',
    })
    .afterClosed();
};
