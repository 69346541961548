<ui-confetti-canvas />

<header>
  <button uiIconButton tertiary (click)="dismiss()">
    <i class="app-icon-close"></i>
  </button>
</header>

<main>
  <h5>{{ "LEAD_GEN_ADS.SUBMIT_NOTICE_HEADLINE" | translate: { companyName: ad.companyName } }}</h5>

  <div class="reward">
    <span>{{ rewardDurationInDays }}</span>
  </div>

  <h4>{{ "LEAD_GEN_ADS.CONGRATULATIONS" | translate }}</h4>

  <p>{{ "LEAD_GEN_ADS.SUBMIT_NOTICE_REWARD_TEXT" | translate: { reward: rewardDurationInDays } }}</p>
</main>

<footer>
  <button uiButton (click)="dismiss()">
    {{ "LEAD_GEN_ADS.DONE_BUTTON" | translate }}
  </button>
</footer>
