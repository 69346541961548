/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ValidationInterceptor } from '@stsm/global/composite/interceptors/validation-interceptor';
import { SystemMaintenanceInterceptor } from '@stsm/system-maintenance/interceptors/system-maintenance.interceptor';

/** Http interceptor providers in outside-in order */
export const HTTP_INTERCEPTOR_PROVIDERS = [
  { provide: HTTP_INTERCEPTORS, useClass: SystemMaintenanceInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ValidationInterceptor, multi: true },
];
