<ui-accordion>
  <ui-accordion-item [flat]="true" [toggleIconPosition]="'start'">
    <div uiAccordionItemHeader class="display-xxxs-h5-medium">
      {{ "STUDYGROUP.SETTINGS_HEADING" | translate }}
    </div>

    <div class="toggle-wrapper text-md-regular">
      <div (click)="onAllowJoiningChange(!studygroup.allowJoining)">
        {{ "STUDYGROUP.ALLOW_JOINING_VIA_LINK" | translate }}
      </div>
      <ui-slide-toggle [checked]="studygroup.allowJoining" (checkedChange)="onAllowJoiningChange($event)" />
    </div>
    <div class="toggle-wrapper text-md-regular">
      <div (click)="onAllowInviteChange(!studygroup.allowInviting)">
        {{ "STUDYGROUP.ALLOW_MEMBERS_TO_INVITE" | translate }}
      </div>
      <ui-slide-toggle [checked]="studygroup.allowInviting" (checkedChange)="onAllowInviteChange($event)" />
    </div>
  </ui-accordion-item>
</ui-accordion>
