import { NEVER, Observable } from 'rxjs';

import { ContextualTutorial, Explanation, TutorialCategory, TutorialMode } from '@stsm/contextual-tutorial';
import { TrackablePage } from '@stsm/global/composite/services/page-view-tracking.service';
import { TutorialId } from '@stsm/user/models/frontend-settings';

export class SpacedRepetitionIntroTutorial extends ContextualTutorial {
  override readonly id: TutorialId = 'tutorial_flashcards_spaced_repetition_intro';
  override readonly category: TutorialCategory = 'spaced_repetition_intro';
  override readonly mode: TutorialMode = 'custom';
  override readonly associatedPages: TrackablePage[] = [TrackablePage.SPACED_REPETITION];
  override readonly explanations: readonly Explanation[] = [];

  protected override readonly trigger$: Observable<unknown> = NEVER; // manual trigger

  private _trackViewCounter: number = 0;

  override trackView(): void {
    super.trackView({ position: ++this._trackViewCounter });
  }
}
