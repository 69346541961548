import { Injectable } from '@angular/core';

import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { PromptDialogComponent } from './prompt-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PromptDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  openDialog(): void {
    this._platformModalService.create({
      component: PromptDialogComponent,
    });
  }
}
