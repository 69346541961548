import { Inject, Injectable } from '@angular/core';
import { loadSpace } from '@usersnap/browser';
import { isNil } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, distinctUntilChanged, distinctUntilKeyChanged, EMPTY, switchMap, take } from 'rxjs';

import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { APP_VERSION } from '@stsm/shared/tokens/app-version.token';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { User } from '@stsm/user/models/user';
import { isPupil } from '@stsm/user/models/util/user-util';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

const USERSNAP_GLOBAL_API_KEY = '43f849c2-6663-450b-9e55-26e7bc7959b8';

// Docs: https://help.usersnap.com/docs/open-the-widget-with-your-own-feedback-button
// The event names are defined in the Usersnap settings ('Target')
const USERSNAP_ACTIVATION_EVENT_NAME = 'open_usersnap_widget';
const CCP_USERSNAP_ACTIVATION_EVENT_NAME = 'open_usersnap_widget_ccp';
const VAIA_USERSNAP_ACTIVATION_EVENT_NAME = 'open_usersnap_widget_vaia';

interface WidgetValues {
  assignee?: string;
  custom?: object;
  labels?: string[];
  visitor?: string;
}

interface WidgetEventApi {
  setValue: <K extends keyof WidgetValues>(key: K, value: WidgetValues[K]) => void;
}

interface WidgetOpenEvent {
  api: WidgetEventApi;
  type: 'open';
}

function isWidgetOpenEvent(event: WidgetOpenEvent | Record<string, unknown>): event is WidgetOpenEvent {
  return event?.type === 'open';
}

@Injectable({
  providedIn: 'root',
})
export class UsersnapService {
  private _usersnapApi: Awaited<ReturnType<typeof loadSpace>> | undefined;

  private _user: User | undefined;
  private _isPremium: boolean = false;

  constructor(
    private readonly _targetMarketProvider: TargetMarketProvider,
    @Inject(ENVIRONMENT) private readonly _environment: EnvironmentBase,
    @Inject(APP_VERSION) private readonly _appVersion: string,
    private readonly _deviceDetectorService: DeviceDetectorService,
    private readonly _translationService: TranslationService,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _authStore: AuthStore,
    private readonly _loggerService: LoggerService,
    @Inject(IS_MOBILE_APP) private readonly _isMobileApp: boolean,
  ) {
    this._userStoreFacade.user$.subscribe((user: User) => (this._user = user));
    this._userStoreFacade.premiumInfo$.subscribe((premiumInfo: PremiumInfo) => (this._isPremium = premiumInfo.isPremium));

    this._authStore.isUserLoggedIn$
      .pipe(
        distinctUntilChanged(),
        switchMap((isUserLoggedIn: boolean) => {
          if (!isUserLoggedIn) {
            return EMPTY;
          }

          return combineLatest([this._userStoreFacade.user$, this._userStoreFacade.premiumInfo$]).pipe(take(1));
        }),
        switchMap(() => this._translationService.language$),
        distinctUntilKeyChanged('value'),
        switchMap(() => this._initialize()),
      )
      .subscribe();
  }

  openFeedbackModal(): void {
    if (!this._usersnapApi) {
      return;
    }

    void this._usersnapApi.logEvent(
      this._environment.CONTENT_CREATORS
        ? CCP_USERSNAP_ACTIVATION_EVENT_NAME
        : this._targetMarketProvider.targetMarket() === 'us'
          ? VAIA_USERSNAP_ACTIVATION_EVENT_NAME
          : USERSNAP_ACTIVATION_EVENT_NAME,
    );
  }

  private async _initialize(): Promise<void> {
    if (this._isMobileApp) {
      return Promise.resolve();
    }

    await this._destroyExistingApi();

    this._usersnapApi = await loadSpace(USERSNAP_GLOBAL_API_KEY);

    await this._usersnapApi.init({ locale: this._translationService.currentLanguage() });

    const userDeviceInfo = this._deviceDetectorService.getDeviceInfo();

    this._usersnapApi.on('open', (event: WidgetOpenEvent | Record<string, unknown>): void => {
      if (!isWidgetOpenEvent(event)) {
        this._loggerService.warn('UsersnapService: open event is not of type WidgetOpenEvent');

        return;
      }

      if (!this._user) {
        this._loggerService.warn('UsersnapService: User is not set');

        return;
      }

      event.api.setValue('visitor', this._user.appUser.email);
      event.api.setValue('custom', {
        project: '[WebApp]',
        userId: this._user.id,
        userName: this._user.appUser.firstName ?? 'No name',
        userType: isPupil(this._user) ? '[PUPIL]' : '[STUDENT]',
        userEmail: this._user.appUser.email,
        isPremium: this._isPremium ? '[PREMIUM User]' : '[REGULAR User]',
        deviceType: this._deviceDetectorService.isMobile()
          ? 'MOBILE'
          : this._deviceDetectorService.isTablet()
            ? 'TABLET'
            : this._deviceDetectorService.isDesktop()
              ? 'DESKTOP'
              : '',
        device: userDeviceInfo.device,
        browser: userDeviceInfo.browser,
        browserVersion: userDeviceInfo.browser_version,
        OS: userDeviceInfo.os,
        OSVersion: userDeviceInfo.os_version,
        version: this._appVersion,
        targetMarket: this._targetMarketProvider.targetMarket(),
      });
    });
  }

  private _destroyExistingApi(): Promise<void> {
    if (!isNil(this._usersnapApi)) {
      return this._usersnapApi.destroy();
    }

    return Promise.resolve();
  }
}
