import { inject } from '@angular/core';
import { isNil } from 'lodash-es';
import { defer, finalize, Observable, of, switchMap, take } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { NetworkService } from '@stsm/shared/services/network.service';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { Studygroup } from '@stsm/studygroups/models/studygroup';
import { Studyset } from '@stsm/studysets/models/studyset';
import { ShareStudysetLocation } from '@stsm/studysets/models/studyset-share-location';
import { StudysetMessageService } from '@stsm/studysets/services/studyset-message.service';
import { StudysetsBaseService } from '@stsm/studysets/services/studysets-base.service';
import { STUDYSETS_SERVICE } from '@stsm/studysets/services/tokens/studysets-service.token';
import { ShareStudysetDialogService } from '@stsm/studysets/shared/components/share-studyset-dialog/share-studyset-dialog.service';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { isOwnStudyset } from '@stsm/studysets/util/functions/is-own-studyset';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { ToastService } from '@stsm/ui-components/dialogs/toast/toast.service';
import { LoadingService } from '@stsm/ui-components/loading-dialog';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

export abstract class ShareStudysetBaseService {
  protected readonly simpleDialogService: SimpleDialogService = inject(SimpleDialogService);
  protected readonly translationService: TranslationService = inject(TranslationService);
  protected readonly loggerService: LoggerService = inject(LoggerService);
  protected readonly studysetsService: StudysetsBaseService = inject(STUDYSETS_SERVICE);
  protected readonly loadingService: LoadingService = inject(LoadingService);
  protected readonly studysetsStoreFacade: StudysetsStoreFacade = inject(StudysetsStoreFacade);
  protected readonly environment: EnvironmentBase = inject(ENVIRONMENT);
  protected readonly networkService: NetworkService = inject(NetworkService);
  protected readonly targetMarketProvider: TargetMarketProvider = inject(TargetMarketProvider);
  protected readonly userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  protected readonly toastService: ToastService = inject(ToastService);
  protected readonly shareStudysetDialogService: ShareStudysetDialogService = inject(ShareStudysetDialogService);
  protected readonly analyticsService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);
  protected readonly studysetMessageService: StudysetMessageService = inject(StudysetMessageService);

  constructor() {
    this.studysetMessageService
      .onTriggerShareStudyset()
      .subscribe(({ studyset, location }: { studyset: Studyset; location: ShareStudysetLocation }): void => {
        void this.shareStudysetLink(studyset, { location });
      });

    this.studysetMessageService
      .onTriggerShareStudygroup()
      .subscribe(({ studyset, studygroup }: { studyset: Studyset; studygroup: Studygroup }): void => {
        this.shareStudygroup(studyset, studygroup);
      });
  }

  shareStudysetWithId(studysetId: number, options: { location: ShareStudysetLocation }): void {
    if (!this.networkService.isConnected()) {
      this._showOfflineAlert();
    } else {
      this.fetchStudyset(studysetId)
        .pipe(take(1))
        .subscribe((studyset: Studyset) => this.shareStudyset(studyset, options));
    }
  }

  getLinkForStudyset(studyset: Studyset, user: User): string {
    return `https://${this.targetMarketProvider.resolveWebAppUrl()}/studyset/${studyset.id}?ref=${user.refId}`;
  }

  getLinkForStudygroup(studyset: Studyset, studygroup: Studygroup, user: User): string {
    return `https://${this.targetMarketProvider.resolveWebAppUrl()}/contribute?token=${studygroup.token}&studyset_id=${studyset.id}&ref=${
      user.refId
    }`;
  }

  shareStudyset(studyset: Studyset, options?: { location: ShareStudysetLocation }): void {
    if (isOwnStudyset(studyset)) {
      this.shareStudysetDialogService.openDialog(studyset.id);
    } else {
      void this.shareStudysetLink(studyset, options);
    }
  }

  shareStudygroup(studyset: Studyset, studygroup: Studygroup): void {
    // Although right now it is not necessary to use the parent studyset because the studyset_id in
    // the shared link is not used, we use it anyway in case we want to create a preview when
    // joining a studygroup in the future. In this case it is important that we have the studysetId
    // of the parent.
    defer(() => (studyset.parentId ? this.fetchStudyset(studyset.parentId) : of(studyset))).subscribe((parentStudyset: Studyset) => {
      this.analyticsService.trackEvent({ action: 'studygroup_share_link' });
      void this.shareStudygroupLink(parentStudyset, studygroup);
    });
  }

  protected fetchStudyset(studysetId: number): Observable<Studyset> {
    return this.studysetsStoreFacade.studysetByIdUnfiltered(studysetId).pipe(
      take(1),
      switchMap((studyset: Studyset | undefined) => {
        if (!isNil(studyset)) {
          return of(studyset);
        } else {
          this.loadingService.showLoading();

          return this.studysetsService
            .getStudyset(studysetId, { shouldFetchOnline: true })
            .pipe(finalize(() => this.loadingService.hideLoading()));
        }
      }),
    );
  }

  private _showOfflineAlert(): void {
    this.simpleDialogService.showAlertMessage('OFFLINE_MODE.DISCLAIMERS.SHARING_OFFLINE_ALERT');
  }

  abstract shareStudysetLink(studyset: Studyset, options?: { location: ShareStudysetLocation }): Promise<void>;
  protected abstract shareStudygroupLink(studyset: Studyset, studygroup: Studygroup): Promise<void>;
}
