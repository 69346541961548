<ng-container *ngIf="currentAdGroup() as currentAdGroup">
  <app-enigma
    *ngIf="currentAdGroup[0] as group"
    labelType="ditchTheAds"
    [shouldSendAdImpression]="!isFullPageRouteActive()"
    [ad]="group.ad"
    [location]="currentAdGroup[1] ? AdLocation.SIDEBAR_SLOT1 : AdLocation.SIDEBAR"
    [preferredMediaOrientation]="group.orientation"
    (getPremiumClick)="onGetPremiumClick()"
  />

  <app-enigma
    *ngIf="currentAdGroup[1] as group"
    [shouldSendAdImpression]="!isFullPageRouteActive()"
    [ad]="group.ad"
    [location]="AdLocation.SIDEBAR_SLOT2"
    [preferredMediaOrientation]="group.orientation"
    (getPremiumClick)="onGetPremiumClick()"
  />
</ng-container>
