import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, Observable, switchMap } from 'rxjs';

import { VOID } from '@stsm/shared/util/rxjs.util';
import { Tag, TagContentFilter, TagData } from '@stsm/tags/models/tag';
import * as TagActions from '@stsm/tags/services/store/tag.actions';
import { TagsStoreFacade } from '@stsm/tags/services/store/tags-store-facade.service';

import { TagsService } from '../../shared/services/tags.service';

@Injectable()
export class TagsEffects {
  /**
   * This effect is responsible for re-fetching tags if yet not available in the store.
   *
   * This happens if the user does not provide any tags at the time of creation of FCs or Exercises and
   * the BE automatically assigns the default tag to the newly created FC or Exercises, that was not
   * existing before in the store.
   */
  checkForMissingTags$: Observable<void> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TagActions.checkForMissingTags),
        distinctUntilChanged(),
        switchMap(({ studysetId, contentFilter, tagIds }: { studysetId: number; tagIds: number[]; contentFilter: TagContentFilter }) =>
          this._tagsStoreFacade.tags(studysetId, contentFilter).pipe(
            switchMap((tags: TagData) => {
              const missingTagIds = tagIds.filter(
                (tagId: number) =>
                  !tags.allParentTags.some((t: Tag) => t.id === tagId) || !tags.studysetTags.some((t: Tag) => t.id === tagId),
              );

              if (missingTagIds.length > 0) {
                return this._tagsService.fetchTags(studysetId, contentFilter);
              }

              return VOID;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _tagsStoreFacade: TagsStoreFacade,
    private readonly _tagsService: TagsService,
  ) {}
}
