<div class="mobile-disclaimer">
  <ui-page-back-button (back)="goBack()" />

  <div class="mobile-disclaimer-flex-container">
    <img class="company-logo" alt="" [src]="logoSource()" />
    <img class="mobile-disclaimer-image" alt="" [src]="imageSource()" />

    <h1 class="mobile-disclaimer-heading">{{ "AUTH.MOBILE_DISCLAIMER.HEADING" | translate }}</h1>

    <div class="mobile-disclaimer-container">
      <p class="mobile-disclaimer-text">{{ "AUTH.MOBILE_DISCLAIMER.TEXT" | translate }}</p>

      <img class="mobile-disclaimer-button-get-app" alt="" [src]="buttonPath$ | async" (click)="openDownloadLink()" />
    </div>
  </div>
</div>
