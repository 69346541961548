import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  // Console CTA for developers
  printApplicationCta(): void {
    console.log(
      '%c                                                                ' +
        '\n                                                                ' +
        '\n                             ######                             ' +
        '\n                         ##############                         ' +
        '\n%c                      ####################                      ' +
        '\n                  ##########  #### ##########                   ' +
        '\n%c              ##########      ####     ##########               ' +
        '\n           ##########         ####         ##########           ' +
        '\n%c        #########             ####            ##########        ' +
        '\n     #########                ####               ##########     ' +
        '\n%c   #######                   ######                  #######    ' +
        '\n   ######                 ############                 #####    ' +
        '\n    ########          ###################          ########     ' +
        '\n%c     ###########   ##########     ###########   ##########      ' +
        '\n         ################              ###############          ' +
        '\n             ##########                  ##########             ' +
        '\n%c               ###########           ###########                ' +
        '\n               ###############    ##############                ' +
        '\n%c               ####    #################    ####                ' +
        '\n               ####        ##########       ####                ' +
        '\n                #####        ######        #####                ' +
        '\n%c                ########      ####     ########                 ' +
        '\n                  ##########  #### ##########                   ' +
        '\n                      ####################                      ' +
        '\n%c                         #############                          ' +
        '\n                             ######                             ' +
        '\n                                                                ' +
        '\n                                                                ' +
        "\n%c               We're hiring!                " +
        '\n                                            ' +
        '\n         www.studysmarter.de/career         ' +
        '\n                                            ',
      'color: #09d6ad; background-color: #243c51',
      'color: #00ceb7; background-color: #243c51',
      'color: #00c6c0; background-color: #243c51',
      'color: #00bdc8; background-color: #243c51',
      'color: #00b4cd; background-color: #243c51',
      'color: #00aad0; background-color: #243c51',
      'color: #00a0d1; background-color: #243c51',
      'color: #0096ce; background-color: #243c51',
      'color: #008bca; background-color: #243c51',
      'color: #1980c2; background-color: #243c51',
      'color: #ffffff; background-color: #243c51; font-weight: bold; font-size: 12pt',
    );
  }
}
