import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { FlashcardsSelectionStore } from '../services/flashcards-selection-store.service';

/**
 * disabled bulk edit when leaving the Flashcard grid component
 */
export const disableFlashcardBulkEditOnLeave: CanDeactivateFn<unknown> = (): boolean => {
  inject(FlashcardsSelectionStore).setIsBulkEditActive(false);

  return true;
};
