import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, tap } from 'rxjs';

import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { switchToVoid } from '@stsm/shared/util/rxjs.util';

import * as UserActions from './user.actions';

@Injectable()
export class UserEffectsBase {
  protected readonly actions$: Actions = inject(Actions);
  protected readonly authStore: AuthStore = inject(AuthStore);
  protected readonly browserStorageService: BrowserStorageService = inject(BrowserStorageService);

  protected readonly onLogout$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        tap(() => {
          this.authStore.setAuthData(undefined);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.IS_PUPIL);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.PREMIUM_INFO);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.FEED_WELCOME_MESSAGE);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.STUDY_REMINDER_REQUEST_SCHEDULE);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.ONBOARDING_SCHEDULE);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.NUMBER_OF_SESSIONS);
          this.browserStorageService.removeItemLocalStorage(GlobalLocalStorageKey.AD_REWARDS_TIMESTAMP_KEY);
        }),
        switchToVoid(),
      ),
    { dispatch: false },
  );
}
