import { isNil } from 'lodash-es';

import { DefaultExperimentOptionValue } from '@stsm/analytics';

export function getDevtoolsInformationForExperimentOptions<OptionValue extends string = DefaultExperimentOptionValue>(
  options?: readonly OptionValue[],
): { label: OptionValue; value: OptionValue }[] {
  if (isNil(options)) {
    return (['control', 'treatment'] as OptionValue[]).map((option: OptionValue) => ({ label: option, value: option }));
  }

  return options.map((option: OptionValue) => ({ label: option, value: option }));
}
