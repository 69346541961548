import { createReducer, on } from '@ngrx/store';
import { isNil } from 'lodash-es';

import { PREMIUM_INFO_MOCK, PremiumInfo } from '@stsm/user/models/premium-info';
import { User, UserFactory } from '@stsm/user/models/user';
import { UserFeature } from '@stsm/user/models/user-feature';

import * as UserActions from './user.actions';

export const userStateKey = 'userState';

export interface UserState {
  user: User | undefined;
  premiumInfo: PremiumInfo | undefined;
}

const initialUserState: UserState = {
  user: undefined,
  premiumInfo: undefined,
};

export const USER_STATE_MOCK: UserState = {
  user: UserFactory.create(),
  premiumInfo: PREMIUM_INFO_MOCK,
};

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.setUser, (state: UserState, props: { user: User }) => {
    return {
      ...state,
      user: props.user,
    };
  }),
  on(UserActions.updateUser, (state: UserState, props: { changes: Partial<User> }) => {
    if (isNil(state.user)) {
      return state;
    }

    return {
      ...state,
      user: {
        ...state.user,
        ...props.changes,
      },
    };
  }),
  on(UserActions.setPremiumInfo, (state: UserState, props: { premiumInfo: PremiumInfo }) => {
    return {
      ...state,
      premiumInfo: props.premiumInfo,
    };
  }),
  on(UserActions.removeUserFeatureGroup, (state: UserState, props: { userFeature: UserFeature }) => {
    if (isNil(state.user)) {
      return state;
    }

    return {
      ...state,
      user: {
        ...state.user,
        featureGroups: state.user.featureGroups.filter((feature: UserFeature) => feature !== props.userFeature),
      },
    };
  }),
  on(UserActions.logout, (state: UserState) => {
    return {
      ...state,
      user: undefined,
      isUserLoggedIn: false,
      premiumInfo: undefined,
    };
  }),
);
