import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input, InputSignalWithTransform, Signal } from '@angular/core';
import { IconFontName } from '@studysmarter/common-styles/lib/icon-font';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';

@Component({
  selector: 'ui-visibility-badge',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './visibility-badge.component.html',
  styleUrl: './visibility-badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisibilityBadgeComponent {
  isPublic: InputSignalWithTransform<boolean, unknown> = input(false, {
    transform: booleanAttribute,
  });

  iconName: Signal<IconFontName> = computed(() => {
    return this.isPublic() ? 'app-icon-subject-interdisciplinary-studies' : 'app-icon-lock-fill';
  });

  labelTranslationKey: Signal<string> = computed(() => {
    return this.isPublic() ? 'SHARE.BADGE_PUBLIC' : 'SHARE.BADGE_PRIVATE';
  });
}
