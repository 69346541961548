<a class="logo-menu-item" (click)="onLogoClick()">
  <img alt="" [src]="logo$ | async" />
</a>

<div *ngrxLet="currentTab$; let currentTab" class="items-stack items">
  @for (item of navigationRoutes; track item.path; let index = $index) {
    @if (item.path !== Tab.JOBS || showJobsTab()) {
      <a [attr.data-cy]="'tabs-button-' + [item.path]" (click)="onNavItemClicked(item.path)">
        <ui-toolbar-item
          [icon]="currentTab === item.path ? item.data.appIconActive : item.data.appIcon"
          [label]="item.data.textTranslationKey | translate"
          [isActive]="currentTab === item.path && !isFullPageRouteActive()"
          [flat]="isMobileLayout()"
        />
      </a>
    }
  }
</div>

<div class="items-stack actions">
  <ui-toolbar-item
    *ngIf="!isMobileApp"
    icon="app-icon-help"
    data-cy="tabs-button-help"
    [label]="'GLOBAL.NAVIGATION_ACTIONS.HELP' | translate"
    (click)="openFeedbackModal()"
  />
  <ui-toolbar-item
    icon="app-icon-referral"
    data-cy="tabs-button-invite-friends"
    [label]="'GLOBAL.REFERRAL' | translate"
    (click)="onInviteFriendsClick()"
  />
</div>
