import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { isNil } from 'lodash-es';

import { Tab } from '@stsm/shared/enums/tab';

// used to redirect users from a deprecated route to the new studyset page
export function studysetRedirectGuard(route: ActivatedRouteSnapshot): UrlTree {
  const router: Router = inject(Router);
  const studysetId = route.paramMap.get('studysetId');

  return router.createUrlTree(!isNil(studysetId) ? ['/studyset', studysetId] : [Tab.LIBRARY]);
}
