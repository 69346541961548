<div class="prefix">
  <ng-content select="[prefix]" />
</div>

<div class="email text-md-medium one-liner">
  {{ email }}
  <span *ngIf="isPending">&nbsp;({{ "STUDYGROUP.PENDING" | translate }})</span>
</div>

<div class="postfix">
  <ng-content select="[postfix]" />
</div>
