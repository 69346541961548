import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { Observable } from 'rxjs';

/** Wrapper service to avoid injecting Platform to get screen sizes */
@Injectable({ providedIn: 'root' })
export class ScreenDimensionsService {
  constructor(private readonly _platform: Platform) {}

  get screenWidth(): number {
    return this._platform.width();
  }

  get screenHeight(): number {
    return this._platform.height();
  }

  get resize$(): Observable<void> {
    return this._platform.resize.asObservable();
  }
}
