import { Injectable } from '@angular/core';

import { SurveyBaseService } from '@stsm/feed';
import { SurveyLocation } from '@stsm/global/models/survey';

@Injectable({ providedIn: 'root' })
export class SurveyService extends SurveyBaseService {
  protected override handleNpsSurvey(): void {
    return;
  }

  protected override startSurvey(_options: {
    location: SurveyLocation;
    declineTranslationKey?: string;
    declineSurveyCallback?: () => void;
    startSurveyCallback?: () => void;
  }): void {
    return;
  }
}
