import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from '../environments/environment';

export function initializeGoogleTagManager(googleTagManagerService: GoogleTagManagerService): () => Promise<void> {
  return (): Promise<void> => {
    if (!environment.INCLUDE_ADDITIONAL_TRACKING) {
      return Promise.resolve();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win = window as any;
    win.dataLayer = win.dataLayer || [];

    /**
     * Some Google Tag Manager events contain a reference to the DOM element that triggered the event (e.g. when using Link Tracking).
     * This causes the DOM element to stay in memory and prevents garbage collection.
     * Patching the push method removes the reference by cloning the DOM element without listeners when pushed to the dataLayer.
     *
     * https://www.voorhoede.nl/en/blog/your-website-probably-has-a-memory-leak/
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    win.dataLayer.push = function (event: any): number {
      if (event['gtm.element']) {
        event['gtm.element'] = event['gtm.element'].cloneNode(true);
      }

      return Array.prototype.push.apply(this, [event]);
    };

    return googleTagManagerService
      .addGtmToDom()
      .then(() => Promise.resolve())
      .catch((error: Error) => {
        // eslint-disable-next-line no-restricted-globals
        console.error(error);
      });
  };
}
