<ui-dialog-template showCloseButton [heading]="'SHARE.SHARE_STUDYSET_HEADER' | translate">
  <div class="content" slot="content" uiThemed colorId="denim">
    <div class="heading">
      <div class="title">
        {{ "STUDYGROUP.MEMBERS" | translate }}
        <button
          *ngIf="showHelp()"
          uiIconButton
          tertiary
          size="sm"
          class="settings-button"
          [uiTooltip]="collaboratorTooltip"
          (click)="openHelp()"
        >
          <i data-cy="studygroup-help-button" class="app-icon-help"></i>
        </button>
        <span class="member-count">{{ studygroup?.members?.length }}</span>
      </div>

      <ng-template #collaboratorTooltip>
        <app-collaborators-tooltip />
      </ng-template>

      <button
        *ngIf="studygroup?.allowInviting || isAdmin"
        uiButton
        quarternary
        accent
        size="sm"
        [disabled]="isShareViaLinkButtonDisabled"
        (click)="shareStudygroupViaLink()"
      >
        <i class="app-icon-link"></i><span>{{ "SHARE.SHARING_DIALOG.COPY_INVITE_LINK" | translate }}</span>
      </button>
    </div>

    <form *ngIf="isAdmin || studygroup?.allowInviting" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
      <ui-form-field>
        <ui-suffix>
          <button type="submit" class="email-suffix" [disabled]="emailControl.value && emailForm.invalid">
            <i class="app-icon-add" [class.icon-color-warning]="emailControl.value && !emailForm.valid"></i>
            <span>{{ "SHARE.SHARING_DIALOG.ADD_USER_BUTTON" | translate }}</span>
          </button>
        </ui-suffix>
        <input
          uiInput
          inputmode="email"
          type="email"
          formControlName="email"
          enterkeyhint="send"
          data-cy="studygroup-email-input"
          [placeholder]="'STUDYGROUP.ENTER_EMAIL' | translate"
          [required]="true"
          (focus)="onInputFocus()"
          (blur)="onInputBlur()"
        />
        <ui-form-error *ngIf="emailControl.value && emailForm.invalid" data-cy="studygroup-email-input-error">
          {{ "STUDYGROUP.INVALID_EMAIL" | translate }}
        </ui-form-error>
      </ui-form-field>
    </form>
    <div class="sub-content">
      <div *ngIf="showSuggestions() && filteredSuggestedEmails.length > 0" class="suggestions">
        <div class="title display-xxxs-h5-medium">
          {{ "STUDYGROUP.SUGGESTIONS_TITLE" | translate }}
        </div>
        <div class="emails">
          <div class="emails-scroll">
            <ng-container *ngFor="let email of filteredSuggestedEmails; trackBy: trackByDefault">
              <button uiButton quarternary (click)="sendInvite(email)">
                {{ email }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngIf="studygroup" class="members-container">
        <ng-container *ngFor="let member of studygroup.members; let i = index; trackBy: trackByObjectId">
          <app-studygroup-email [email]="member.email" [class.admin]="member.isAdmin">
            <i class="app-icon-user-fill" prefix></i>
            <button
              *ngIf="!member.isAdmin && isAdmin"
              uiIconButton
              tertiary
              postfix
              size="xs"
              data-cy="studygroup-remove-member-button"
              (click)="onRemoveMember(member.id, i)"
            >
              <i class="app-icon-close"></i>
            </button>
            <ui-tag *ngIf="member.isAdmin" postfix [colorId]="'green'">Admin</ui-tag>
            <div *ngIf="!member.isAdmin && member.id === userId" class="postfix-admin-text" postfix>
              {{ "STUDYGROUP.YOU" | translate }}
            </div>
          </app-studygroup-email>
        </ng-container>

        <ng-container *ngFor="let email of studygroup.pendingEmails; trackBy: trackByDefault">
          <app-studygroup-email isPending [email]="email">
            <i class="app-icon-user-fill" prefix></i>
            <button
              *ngIf="isAdmin"
              uiIconButton
              tertiary
              postfix
              size="xs"
              data-cy="studygroup-remove-invite-button"
              (click)="onRemoveInvite(email)"
            >
              <i class="app-icon-close"></i>
            </button>
          </app-studygroup-email>
        </ng-container>
      </div>

      <app-studygroup-settings *ngIf="studyset && studygroup && isAdmin" [studyset]="studyset" [studygroup]="studygroup" />
    </div>
  </div>

  <footer *ngIf="showFooter && (isKeyboardVisible$ | async) === false" slot="footer">
    <ng-container *ngIf="!studyset?.shared && isUserCreator; else sharedStudysetTemplate">
      <ui-visibility-badge [isPublic]="studyset?.shared" />
      <div class="display-xxxs-h5-medium">
        {{ "SHARE.MAKE_STUDYSET_PUBLIC_DISCLAIMER_TITLE" | translate: { studysetName: studyset?.name ?? "" } }}
      </div>
      <div class="text-sm-regular make-public-disclaimer">
        {{ "SHARE.MAKE_STUDYSET_PUBLIC_DISCLAIMER_TEXT" | translate }}
      </div>
      <button class="make-public-button" data-cy="studygroup-make-studyset-public-button" uiButton secondary (click)="onShareStudyset()">
        <i slot="start" class="app-icon-subject-interdisciplinary-studies"></i>
        <span>{{ "SHARE.MAKE_STUDYSET_PUBLIC" | translate }}</span>
      </button>
    </ng-container>

    <ng-template #sharedStudysetTemplate>
      <div class="shared-studyset-container">
        <div class="pre-heading">
          <ui-visibility-badge [isPublic]="studyset?.shared" />

          <div *ngIf="studyset" class="consumer-count-container">
            <div *ngIf="studyset.consumerCount > 0; else no_consumers" class="text-sm-medium">
              {{ "STUDYGROUP.LIKES_COUNT" | translate: { count: studyset.consumerCount } }}
            </div>
            <i class="app-icon-follow-fill"></i>

            <ng-template #no_consumers>
              <div class="text-sm-medium">
                {{ "STUDYGROUP.NO_LIKES" | translate }}
              </div>
            </ng-template>
          </div>
        </div>

        <div class="display-xxxs-h5-medium">
          {{ "SHARE.PUBLIC_STUDYSET_DISCLAIMER_TITLE" | translate: { studysetName: studyset?.name ?? "" } }}
        </div>
        <div class="text-sm-regular make-public-disclaimer">
          {{ "SHARE.PUBLIC_STUDYSET_DISCLAIMER" | translate: { publishedAt: publishedAt ? (publishedAt | niceDate: "long") : "" } }}
        </div>

        <button class="share-button" uiButton secondary (click)="shareStudyset()">
          <i slot="start" [class]="shareIconName"></i>
          <span>
            {{ "SHARE.SHARING_DIALOG.SHARE_LINK" | translate }}
          </span>
        </button>
      </div>
    </ng-template>
  </footer>
</ui-dialog-template>
