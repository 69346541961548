import { Injectable } from '@angular/core';

import { ContextualTutorialBaseService } from '@stsm/contextual-tutorial';

import { MagicMarkerTutorial } from '../tutorials/magic-marker-tutorial';
import { SpacedRepetitionIntroTutorial } from '../tutorials/spaced-repetition-intro-tutorial';

@Injectable({ providedIn: 'root' })
export class ContextualTutorialService extends ContextualTutorialBaseService {
  constructor() {
    super([MagicMarkerTutorial, SpacedRepetitionIntroTutorial]);
  }
}
