import { Observable, switchMap } from 'rxjs';

import { ContextualTutorial, Explanation, TutorialCategory, TutorialMode } from '@stsm/contextual-tutorial';
import { TrackablePage } from '@stsm/global/composite/services/page-view-tracking.service';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { TutorialId } from '@stsm/user/models/frontend-settings';

export class MagicMarkerTutorial extends ContextualTutorial {
  override readonly id: TutorialId = 'tutorial_magic_marker';
  override readonly category: TutorialCategory = 'magic-marker';
  override readonly mode: TutorialMode = 'dialog';

  override explanations: Explanation[] = [
    {
      animatedIllustration: 'magic-marker',
      titleTranslationKey: 'CONTEXTUAL_TUTORIAL.MAGIC_MARKER_AI.TITLE',
      contentTranslationKey: 'CONTEXTUAL_TUTORIAL.MAGIC_MARKER_AI.CONTENT',
      continueButtonLabel: 'CONTEXTUAL_TUTORIAL.MAGIC_MARKER_AI.BUTTON_LABEL',
    },
  ];

  override readonly associatedPages: TrackablePage[] = [TrackablePage.DOCUMENT_VIEWER_WEB];

  protected override readonly trigger$: Observable<void> = this.pageViewTrackingService
    .observe(this.associatedPages[0] as TrackablePage)
    .pipe(switchMap((pageViews: number) => (pageViews === 1 ? VOID : this.complete())));
}
