import { ChangeDetectionStrategy, Component, Signal, TrackByFunction } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, map, Observable } from 'rxjs';

import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { AuthData } from '@stsm/auth/models/auth-data';
import { trackByIndex } from '@stsm/shared/util/generic-utils';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { environment } from '../../../environments/environment';

interface CookieParams {
  isLoggedIn: boolean;
  isPremium: boolean;
  token: string | undefined;
  userId: number | undefined;
}

const DOMAIN_COOKIE_URLS: string[] = [
  'https://www.studysmarter.co.uk/WebAppCookieUK.php',
  'https://www.vaia.com/WebAppCookieUS.php',
  'https://www.studysmarter.es/WebAppCookieES.php',
  'https://www.studysmarter.fr/WebAppCookieFR.php',
  'https://www.studysmarter.it/WebAppCookieIT.php',
];

const DOMAIN_COOKIE_URL_E2E = 'https://website.e2e.wp.studysmarter-test.de/WebAppCookieDEMO.php';

@Component({
  selector: 'app-cookie-util',
  templateUrl: './cookie-util.component.html',
  styleUrls: ['./cookie-util.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieUtilComponent {
  protected readonly cookieParams$: Observable<CookieParams> = combineLatest([
    this._authStore.authData$,
    this._authStore.isUserLoggedIn$,
    this._userStoreFacade.premiumInfo$,
  ]).pipe(
    map(([authData, isUserLoggedIn, premiumInfo]: [AuthData | undefined, boolean, PremiumInfo]) => ({
      isLoggedIn: isUserLoggedIn,
      isPremium: premiumInfo.isPremium,
      token: authData?.token,
      userId: authData?.id,
    })),
  );

  protected readonly domainCookieUrls: Signal<string[]> = toSignal(
    this.cookieParams$.pipe(
      map((cookieParams: CookieParams) => {
        // used for multi-domain e2e tests
        if (environment.name === 'DEMO') {
          return [this._appendQueryParams(DOMAIN_COOKIE_URL_E2E, cookieParams)];
        }

        return DOMAIN_COOKIE_URLS.map((url: string) => this._appendQueryParams(url, cookieParams));
      }),
    ),
    { initialValue: [] },
  );

  protected readonly trackByIndex: TrackByFunction<string> = trackByIndex;

  /** Enable cookies for non-production environments */
  private readonly _isTestMode: boolean = false;

  get shouldApplyAuthCookies(): boolean {
    const isCorrectEnvironment = (environment.production && !environment.CONTENT_CREATORS) || environment.name === 'DEMO';

    return isCorrectEnvironment || this._isTestMode;
  }

  constructor(
    private readonly _authStore: AuthStore,
    private readonly _userStoreFacade: UserStoreFacade,
  ) {}

  private _appendQueryParams(url: string, { isLoggedIn, token, userId, isPremium }: CookieParams): string {
    if (!isLoggedIn) {
      return url.concat('?login=false');
    }

    return url.concat(`?login=true&user_id=${userId}&token=${token}&is_premium=${isPremium}`);
  }
}
