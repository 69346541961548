import { inject } from '@angular/core';
import { CanActivateChildFn, Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';

import { AuthUrl } from '@stsm/auth/models/auth-url';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

/**
 * There are no anonymous users being created anymore. It is still possible though that an anonymous user exists in localStorage.
 * To simplify the transition, existing anonymous users are just being logged out.
 * Eventually, this guard can then be removed.
 */
export const isRouteAllowedForAnonymousUserGuard: CanActivateChildFn = (): Observable<boolean | UrlTree> => {
  // At this point we already know that there is a localUser in localStorage.

  const userStoreFacade = inject(UserStoreFacade);

  const router = inject(Router);

  return userStoreFacade.user$.pipe(
    take(1),
    map((user: User) => {
      if (user.isAnonymous) {
        userStoreFacade.logout();

        return router.parseUrl(AuthUrl.GET_STARTED);
      }

      return true;
    }),
  );
};
