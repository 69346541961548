import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import * as UserActions from '@stsm/user/store/user.actions';

import * as TaskActions from './task.actions';

@Injectable()
export class TaskEffects {
  removeTasksOnLogout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.logout),
      map(() => TaskActions.clearTasks()),
    ),
  );

  constructor(private readonly _actions$: Actions) {}
}
