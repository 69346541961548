import { createReducer, on } from '@ngrx/store';

import * as AppActions from './app.actions';

export const APP_STATE_KEY = 'appState';

export interface AppState {
  swipeGestureEnabled: boolean;
  screenOrientation: string | undefined;
}

const initialAppState: AppState = {
  swipeGestureEnabled: true,
  screenOrientation: undefined,
};

export const APP_STATE_MOCK: AppState = {
  swipeGestureEnabled: true,
  screenOrientation: 'portrait',
};

export const appReducer = createReducer<AppState>(
  initialAppState,
  on(AppActions.setScreenOrientation, (state: AppState, props: { screenOrientation: string }) => {
    return {
      ...state,
      screenOrientation: props.screenOrientation,
    };
  }),
  on(AppActions.setSwipeGestureEnabled, (state: AppState, props: { swipeGestureEnabled: boolean }) => {
    return {
      ...state,
      swipeGestureEnabled: props.swipeGestureEnabled,
    };
  }),
);
