<div class="modal-wrapper">
  <div class="modal-heading">
    <div *ngIf="titleTranslationKey" class="heading">
      {{ titleTranslationKey | translate }}
    </div>
    <button uiIconButton secondary size="sm" (click)="close()">
      <i class="app-icon-close"></i>
    </button>
  </div>

  <swiper-container uiSwiper init="false" [options]="swiperOptions" (slideChange)="onActiveIndexChange($event)">
    @for (slide of slides; track $index) {
      <swiper-slide uiSwiperSlide>
        <div class="slide-container">
          <div class="slide-content">
            <div class="slide-image-wrapper">
              <!-- image -->
              <img *ngIf="resolveImageSource(slide) | async as src" class="slide-image" alt="" [src]="src" />

              <!-- illustration -->
              <div *ngIf="slide.illustrationName as name" uiAnimatedIllustration [name]="name"></div>
            </div>

            <!-- slide title -->
            <div class="slide-subheading">{{ slide.subtitleTranslationKey | translate }}</div>

            <!-- slide text -->
            <div class="slide-text">
              <span [innerHTML]="slide.textTranslationKey | translate"></span>
              <!-- optional link -->
              <a *ngIf="slide.linkTranslationKey" target="_blank" [href]="slide.linkTranslationKey | translate">
                {{ slide.linkTextTranslationKey ?? slide.linkTranslationKey | translate }}
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
    }
  </swiper-container>

  <div class="button-container">
    <button *ngIf="!isEnd || confirmButton" uiButton secondary size="lg" data-cy="whats-new-modal-secondary-button" (click)="close()">
      {{ dismissTranslationKey || "WHATS_NEW.CLOSE" | translate }}
    </button>
    <button uiButton size="lg" data-cy="whats-new-modal-primary-button" (click)="nextSlide()">
      {{ (isEnd ? confirmTranslationKey || "WHATS_NEW.DONE" : "WHATS_NEW.NEXT") | translate }}
    </button>
  </div>
</div>
