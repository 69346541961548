import { Injectable } from '@angular/core';
import { map, Observable, of, take } from 'rxjs';

import { PlanDuration, PremiumPlan, PremiumPlansWithStorePricesResult } from '@stsm/premium/models/premium-plan';
import { PremiumPlansService } from '@stsm/premium/services/premium-plans.service';

@Injectable({ providedIn: 'root' })
export class PremiumPlansWebService extends PremiumPlansService {
  override getPremiumPlansWithStorePrices(): Observable<PremiumPlansWithStorePricesResult> {
    return this.getPremiumPlans().pipe(map((premiumPlans: PremiumPlan[]) => ({ premiumPlans, hasPriceFetchingError: false })));
  }

  override getYearlyDiscountPremiumPlan(): Observable<PremiumPlan> {
    return of(this.getHardCodedYearlyDiscountPremiumPlan());
  }

  override getDesignTrialPremiumPlans(): Observable<PremiumPlansWithStorePricesResult> {
    return this.getPremiumPlans().pipe(
      take(1),
      map((premiumPlans: PremiumPlan[]) => [
        ...premiumPlans.filter((premiumPlan: PremiumPlan) => premiumPlan.duration === PlanDuration.YEARLY),
        this.getHardCodedDesignTrialPremiumPlan(),
      ]),
      map((premiumPlans: PremiumPlan[]) => ({ premiumPlans, hasPriceFetchingError: false })),
    );
  }
}
