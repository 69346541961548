import { Injectable } from '@angular/core';

import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { UpdateDialogComponent } from './update-dialog.component';

@Injectable({ providedIn: 'root' })
export class UpdateDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  open(): DialogRef<UpdateDialogComponent> {
    return this._platformModalService.create({
      component: UpdateDialogComponent,
      disposeOnNavigation: false,
      allowBackdropDismiss: false,
    });
  }
}
