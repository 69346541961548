import { CanDeactivateFn } from '@angular/router';
import { isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { ExerciseEditComponent } from './exercise-edit.component';

export const exerciseFormCanDeactivateGuard: CanDeactivateFn<ExerciseEditComponent> = (
  component: ExerciseEditComponent,
): Observable<boolean> => {
  return !isNil(component.createExerciseFormComponentRef) ? component.createExerciseFormComponentRef.checkUnsaved() : of(true);
};
