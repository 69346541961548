<div *ngIf="showBanner" class="system-maintenance-banner" [@moveInOut] (click)="close()">
  <img uiIllustration="announcement" alt="" />
  <div class="maintenance-text-wrapper">
    <div class="heading">
      {{ "SYSTEM_MAINTENANCE.PLANNED_MAINTENANCE" | translate }}
    </div>

    @if (plannedStart && plannedEnd) {
      <div class="info">
        {{
          "SYSTEM_MAINTENANCE.PLANNED_MAINTENANCE_TEXT"
            | translate: { start: plannedStart | localeDateString, end: plannedEnd | localeDateString }
        }}
      </div>
    }
  </div>
  <i class="app-icon-close close-icon"></i>
</div>
