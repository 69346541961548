<header data-cy="lead-question">
  {{ question.question }}
</header>

<ng-container *ngIf="isSelect; else textInput">
  @for (answer of question.answers; track $index) {
    <section
      tabindex="0"
      role="option"
      data-cy="lead-question-option"
      [class.selected]="answers().includes(answer)"
      (click)="toggleAnswer(answer)"
      (keyup.enter)="toggleAnswer(answer)"
    >
      <p>{{ answer }}</p>

      <i *ngIf="answers().includes(answer)" [class]="isMultiSelect ? 'app-icon-check' : 'app-icon-overview-fill'"></i>
    </section>
  }
</ng-container>

<ng-template #textInput>
  <ui-form-field>
    <input
      uiInput
      ngModel
      type="text"
      data-cy="lead-question-input"
      [placeholder]="'LEAD_GEN_ADS.CUSTOM_QUESTION_TEXT_INPUT_PLACEHOLDER' | translate"
      (ngModelChange)="toggleAnswer($event)"
    />
  </ui-form-field>
</ng-template>
