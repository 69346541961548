<img uiIllustration="refresh" alt="" />
<h3>{{ "UPDATE_DIALOG.TITLE" | translate }}</h3>
<p>{{ "UPDATE_DIALOG.DESCRIPTION" | translate }}</p>
<div>
  <button uiButton class="start-button" (click)="reload()">
    <i slot="start" class="app-icon-refresh"></i>
    <span>{{ "UPDATE_DIALOG.RELOAD" | translate }}</span>
  </button>
</div>
<div>
  <button uiButton tertiary class="maybe-later-button" (click)="close()">
    {{ "UPDATE_DIALOG.MAY_BE_LATER" | translate }}
  </button>
</div>
