import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LeadQuestion, LeadQuestionType } from '@stsm/global/models/lead-question';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { FormFieldComponent } from '@stsm/ui-components/form-field';
import { InputDirective } from '@stsm/ui-components/input';

@Component({
  selector: 'app-lead-gen-ad-modal-lead-question',
  standalone: true,
  imports: [NgIf, InputDirective, FormFieldComponent, FormsModule, TranslatePipe],
  styleUrls: ['./lead-gen-ad-modal-lead-question.component.scss'],
  templateUrl: './lead-gen-ad-modal-lead-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'data-cy': 'lead-gen-ad-modal-custom-question' },
})
export class LeadGenAdModalLeadQuestionComponent {
  @Input({ required: true }) question!: LeadQuestion;

  @Output() readonly setAnswers: EventEmitter<string[]> = new EventEmitter<string[]>();

  protected get isMultiSelect(): boolean {
    return this.question.type === LeadQuestionType.MULTI_SELECT;
  }

  protected get isSelect(): boolean {
    return this.isMultiSelect || this.question.type === LeadQuestionType.SINGLE_SELECT;
  }

  protected answers: WritableSignal<string[]> = signal([]);

  /**
   * Toggles the answer for the question.
   *
   * For multi-select and single-select questions, this will add or remove the answer from the list of answers.
   *
   * For all other question types, this will set the answer as the only answer.
   *
   * @param answer The answer user provided.
   * @protected
   */
  protected toggleAnswer(answer: string): void {
    if (this.isSelect) {
      if (this.answers().includes(answer)) {
        this.answers.update((answers: string[]) => answers.filter((currentAnswer: string) => currentAnswer !== answer));
      } else if (this.isMultiSelect) {
        this.answers.update((answers: string[]) => [...answers, answer]);
      } else {
        this.answers.set([answer]);
      }
    } else {
      this.answers.set([answer]);
    }

    this.setAnswers.emit(this.answers());
  }
}
