import { Inject, Injectable } from '@angular/core';
import { filter, Subject, switchMap, takeWhile } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { getElapsedTimeInSeconds } from '@stsm/date/functions/get-elapsed-time-in-seconds';
import { AppActiveService } from '@stsm/global/composite/services/app-active.service';
import { Ad } from '@stsm/global/models/ad';
import { tapOnce } from '@stsm/shared/util/rxjs.util';

import { LeadGenAdModalService } from '../components/lead-gen-ad/lead-gen-ad-modal.service';
import { AdClickEvent } from '../models/ad-click-event';

import { AdLinkOpeningBaseService } from './ad-link-opening-base.service';

interface LastOpenedAdInfo {
  ad: Ad;
  location: string;
}

@Injectable({ providedIn: 'root' })
export class AdLinkOpeningWebService extends AdLinkOpeningBaseService {
  private readonly _lastOpenedAd$: Subject<LastOpenedAdInfo> = new Subject<LastOpenedAdInfo>();

  constructor(
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
    private readonly _appActiveService: AppActiveService,
    private readonly _leadGenAdModalService: LeadGenAdModalService,
  ) {
    super();

    this._lastOpenedAd$
      .pipe(
        switchMap(({ ad, location }: LastOpenedAdInfo) => {
          let hasLeftApp = false;
          const startDate = new Date();

          return this._appActiveService.isAppActive$.pipe(
            tapOnce(() => (hasLeftApp = true), { withCondition: (isAppActive: boolean) => !isAppActive }),
            filter(Boolean),
            tapOnce(
              () => {
                this._analyticsService.trackEvent({
                  action: 'offer_return_to_app',
                  properties: {
                    campaign_name: ad.campaignName,
                    duration: getElapsedTimeInSeconds(startDate),
                    location,
                  },
                });
              },
              { withCondition: () => hasLeftApp },
            ),
            takeWhile(() => !hasLeftApp),
          );
        }),
      )
      .subscribe();
  }

  handleAdClick({ ad, mediaOrientation, location }: AdClickEvent): void {
    if (ad.isLeadGenAd) {
      this._leadGenAdModalService.openLeadGenAdModal({ ad });

      return;
    }

    this._lastOpenedAd$.next({ ad, location });

    const link = this.getAdLinkForMediaOrientation(ad, mediaOrientation);

    window.open(link, '_blank');
  }
}
