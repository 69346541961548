import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isNil } from 'lodash-es';
import { distinctUntilKeyChanged, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';

import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { LayoutStore } from '@stsm/shared/services/layout-store.service';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetUpdateBuilder } from '@stsm/studysets/models/studyset-update-builder';
import * as StudysetActions from '@stsm/studysets/store/studyset.actions';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import * as UserActions from '@stsm/user/store/user.actions';

import { StudysetNumbersService } from './studyset-numbers.service';

export abstract class StudysetEffectsBase {
  protected readonly actions$: Actions = inject(Actions);
  protected readonly studysetNumbersService: StudysetNumbersService = inject(StudysetNumbersService);
  protected readonly routerStoreFacade: RouterStoreFacade = inject(RouterStoreFacade);
  protected readonly studysetsStoreFacade: StudysetsStoreFacade = inject(StudysetsStoreFacade);
  protected readonly layoutStore: LayoutStore = inject(LayoutStore);

  // eslint-disable-next-line @typescript-eslint/typedef
  protected syncLayoutWithCurrentStudyset$ = createEffect(
    () =>
      this.routerStoreFacade.studysetIdFromRoute$.pipe(
        switchMap((studysetId: number | undefined) =>
          !isNil(studysetId) ? this.studysetsStoreFacade.studysetById(studysetId).pipe(distinctUntilKeyChanged('colorId')) : of(undefined),
        ),
        tap((studyset: Studyset | undefined) => this.layoutStore.setCurrentTheme(studyset?.colorId)),
      ),
    { dispatch: false },
  );

  // eslint-disable-next-line @typescript-eslint/typedef
  protected clearStudysetsOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      map(() => StudysetActions.clearStudysets()),
    ),
  );

  // eslint-disable-next-line @typescript-eslint/typedef
  protected removeContentForStudyset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StudysetActions.removeStudyset),
      map(({ studysetId }: { studysetId: number }) => StudysetActions.removeContentForStudyset({ studysetId })),
    ),
  );

  protected incrementSubtopicCount$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StudysetActions.addStudyset),
        mergeMap(({ studyset }: { studyset: Studyset }) => {
          return isNil(studyset.parentId)
            ? VOID
            : this.studysetNumbersService.applyActionToStudysets([studyset.parentId], (builder: StudysetUpdateBuilder) =>
                builder.subtopicCount(builder.studyset.subtopicCount + 1),
              );
        }),
      ),
    { dispatch: false },
  );

  protected updateCountsWhenSubtopicIsDeleted$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StudysetActions.deleteStudyset),
        mergeMap(({ studyset }: { studyset: Studyset }) => {
          return isNil(studyset.parentId)
            ? VOID
            : this.studysetNumbersService.applyActionToStudysets([studyset.parentId], (builder: StudysetUpdateBuilder) => {
                builder.subtopicCount(Math.max(builder.studyset.subtopicCount - 1, 0));

                // flashcard related counts
                builder.flashcardCount(Math.max(builder.studyset.flashcardCount - studyset.flashcardCount, 0));
                builder.flashcardGoodCount(Math.max(builder.studyset.flashcardGoodCount - studyset.flashcardGoodCount, 0));
                builder.flashcardMediumCount(Math.max(builder.studyset.flashcardMediumCount - studyset.flashcardMediumCount, 0));
                builder.flashcardBadCount(Math.max(builder.studyset.flashcardBadCount - studyset.flashcardBadCount, 0));
                builder.flashcardTrashCount(Math.max(builder.studyset.flashcardTrashCount - studyset.flashcardTrashCount, 0));
                builder.flashcardUnseenCount(Math.max(builder.studyset.flashcardUnseenCount - studyset.flashcardUnseenCount, 0));

                // slideset count
                builder.slidesetCount(Math.max(builder.studyset.slidesetCount - studyset.slidesetCount, 0));

                // Summary related counts
                builder.summaryCount(Math.max(builder.studyset.summaryCount - studyset.summaryCount, 0));

                return builder;
              });
        }),
      ),
    { dispatch: false },
  );
}
