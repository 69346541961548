import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';

import { ONBOARDING_STORE } from '../onboarding-store.token';

export const onboardingRunningGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);

  return inject(ONBOARDING_STORE).isRunningUnfiltered$.pipe(
    take(1),
    map((isOnboardingRunning: boolean | undefined) => {
      if (!isOnboardingRunning) {
        return router.parseUrl('');
      }

      return true;
    }),
  );
};
