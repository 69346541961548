import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { map, of, switchMap, take } from 'rxjs';

import { StandaloneEditorDraftStatus } from '@stsm/flashcards/creation/web/flashcard-standalone-editor/store/standalone-editor-store.service';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';

import { StandaloneEditorComponent } from './standalone-editor.component';

export const standaloneEditorCanDeactivateGuard: CanDeactivateFn<StandaloneEditorComponent> = (component: StandaloneEditorComponent) => {
  const translationService = inject(TranslationService);
  const simpleDialogService = inject(SimpleDialogService);

  return component.draftStatus$.pipe(
    take(1),
    map((draftStatus: StandaloneEditorDraftStatus) => draftStatus.incompleteFlashcardsCount === 0),
    switchMap((canDeactivate: boolean) => {
      if (canDeactivate) {
        return of(true);
      }

      return simpleDialogService
        .scheduleConfirm({
          heading: translationService.get('FLASHCARD.STANDALONE_EDITOR.DIALOG_TITLE'),
          text: translationService.get('FLASHCARD.STANDALONE_EDITOR.DIALOG_TEXT'),
          confirmText: translationService.get('GLOBAL.DISCARD_CHANGES'),
          isDestructive: true,
        })
        .pipe(map(Boolean));
    }),
  );
};
