<ui-dialog-template showCloseButton heading="Edit prompt template">
  <div slot="sub-header">
    This prompt is stored in local storage under the key: <b>{{ formControl.value }}</b>
  </div>
  <div slot="content">
    <ui-select [formControl]="formControl" (valueChanges)="selectPrompt($event)">
      @for (prompt of prompts; track $index) {
        <ui-option [value]="prompt.promptId">
          {{ prompt.promptId }}
        </ui-option>
      }
    </ui-select>
    <textarea [(ngModel)]="promptTemplate"></textarea>
  </div>

  <div slot="footer" class="footer">
    <button uiButton (click)="savePrompt()">Save</button>
    <button uiButton secondary (click)="clearPrompt()">Clear</button>
    <button uiButton secondary (click)="setPromptDefaults()">Set defaults</button>
  </div>
</ui-dialog-template>
