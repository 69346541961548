<router-outlet />

<app-update />

<app-devtools *ngIf="devtoolsEnabled" />

<app-cookie-util />

<app-system-maintenance-banner />

<ng-container #pixelContainer />
