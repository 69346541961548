import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';

@Component({
  selector: 'app-collaborators-tooltip',
  templateUrl: './collaborators-tooltip.component.html',
  styleUrls: ['./collaborators-tooltip.component.scss'],
  standalone: true,
  imports: [TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorsTooltipComponentComponent {}
