import { Animation, createAnimation } from '@ionic/angular/standalone';
import { isNil, range } from 'lodash-es';

import { BreakpointThreshold } from '@stsm/shared/constants/breakpoints';
import { TAB_ROOT_ION_PAGE_CLASS_NAME } from '@stsm/shared/constants/tab-root-ion-page-class-name';
import { getIonPageElement } from '@stsm/shared/util/html-utils';

interface RootTransitionOpts {
  enteringElIndex: number;
  leavingElIndex: number;
  allIonPageElements: Element[];
}

// using a custom type based on the TransitionOptions type from @ionic/angular to avoid vite import warnings
// see https://github.com/ionic-team/ionic-framework/blob/29d4410aa594009cedc0f2545b9d774e6036e8d9/core/src/utils/transition/index.ts#L294
interface TransitionOptions {
  enteringEl: HTMLElement;
  leavingEl: HTMLElement | undefined;
  baseEl: HTMLElement;
  direction: 'forward' | 'back';
}

const shouldReduceMotion = (): boolean => {
  return window.matchMedia(`(prefers-reduced-motion: reduce)`).matches;
};

const getDuration = (): number => (shouldReduceMotion() ? 0 : DURATION);

const isTabRootPage = (pageEl: Element): boolean => pageEl.classList.contains(TAB_ROOT_ION_PAGE_CLASS_NAME);

const TRANSFORM_BACKGROUND = 'translateY(-16px) scaleX(0.97)';
const TRANSFORM_BACKGROUND_TAB_ROOT_PAGE = 'translateY(0) scaleX(0.97)';
const TRANSFORM_BACKGROUND_MOBILE = 'translateY(0) scaleX(1)';
const TRANSFORM_ACTIVE = 'translateY(0) scaleX(1)';
const TRANSFORM_BEFORE_MOVE_UP = 'translateY(100%)';
const OPACITY_BACKGROUND = 0.5;
const OPACITY_ACTIVE = 1;
const OPACITY_INVISIBLE = 0;
const DURATION = 500;
const EASE = 'cubic-bezier(0.36,0.66,0,1)';
const Z_INDEX_BACKGROUND = 100;
const Z_INDEX_ACTIVE = 101;

const getBackgroundTransform = ({ isTabRoot, isMobileLayout }: { isTabRoot: boolean; isMobileLayout: boolean }): string => {
  return isMobileLayout ? TRANSFORM_BACKGROUND_MOBILE : isTabRoot ? TRANSFORM_BACKGROUND_TAB_ROOT_PAGE : TRANSFORM_BACKGROUND;
};

export const scaffoldContentTransitionAnimation = (_: HTMLElement, opts: TransitionOptions): Animation => {
  const { enteringEl, leavingEl } = opts;

  const allIonPageElements = Array.from((opts.baseEl as HTMLIonRouterElement).children);

  let leavingElIndex: number | undefined = undefined;
  const enteringElIndex = allIonPageElements.indexOf(enteringEl);

  if (leavingEl) {
    leavingElIndex = allIonPageElements.indexOf(leavingEl);
  }

  const isRootTransition = leavingElIndex !== undefined && enteringElIndex < leavingElIndex;

  const direction = isRootTransition ? 'back' : opts.direction;

  return direction === 'forward'
    ? forwardTransition(enteringEl, leavingEl)
    : backwardsTransition(
        enteringEl,
        leavingEl,
        isRootTransition && leavingElIndex !== undefined
          ? {
              enteringElIndex,
              leavingElIndex,
              allIonPageElements,
            }
          : undefined,
      );
};

function forwardTransition(enteringEl: HTMLElement, leavingEl: HTMLElement | undefined): Animation {
  const animation = createAnimation().duration(getDuration()).easing(EASE).fill('both');

  const enteringPageAnimation = createAnimation()
    .addElement(getIonPageElement(enteringEl))
    .beforeRemoveClass('ion-page-invisible')
    .beforeStyles({
      'z-index': Z_INDEX_ACTIVE,
      transform: TRANSFORM_BEFORE_MOVE_UP,
    })
    .fromTo('transform', TRANSFORM_BEFORE_MOVE_UP, TRANSFORM_ACTIVE)
    .afterStyles({
      'z-index': Z_INDEX_ACTIVE,
      transform: TRANSFORM_ACTIVE,
    })
    // used for e2e tests to properly wait until a page is fully transitioned
    .afterAddClass('page-presented');

  animation.addAnimation(enteringPageAnimation);

  if (leavingEl) {
    const leavingPageEl = getIonPageElement(leavingEl);
    const isLeavingPageTabRoot = isTabRootPage(leavingPageEl);

    const isMobileLayout = window.innerWidth < BreakpointThreshold.WEB;

    const leavingPageAnimation = createAnimation()
      .addElement(leavingPageEl)
      .beforeAddClass('keep-visible')
      .beforeStyles({
        'z-index': Z_INDEX_BACKGROUND,
        transform: TRANSFORM_ACTIVE,
        opacity: OPACITY_ACTIVE,
      })
      .keyframes([
        { offset: 0, opacity: OPACITY_ACTIVE },
        { offset: 0.95, opacity: OPACITY_ACTIVE },
        { offset: 1, opacity: OPACITY_BACKGROUND },
      ])
      .fromTo('transform', TRANSFORM_ACTIVE, getBackgroundTransform({ isTabRoot: isLeavingPageTabRoot, isMobileLayout }))
      .afterStyles({
        'z-index': Z_INDEX_BACKGROUND,
        transform: getBackgroundTransform({ isTabRoot: isLeavingPageTabRoot, isMobileLayout }),
        opacity: OPACITY_BACKGROUND,
      });

    animation.addAnimation(leavingPageAnimation);
  }

  return animation;
}

function backwardsTransition(
  enteringEl: HTMLElement,
  leavingEl: HTMLElement | undefined,
  rootTransitionOpts: RootTransitionOpts | undefined,
): Animation {
  const animation = createAnimation().duration(getDuration()).easing(EASE).fill('both');

  const enteringPageEl = getIonPageElement(enteringEl);
  const isEnteringPageTabRoot = isTabRootPage(enteringPageEl);

  const isMobileLayout = window.innerWidth < BreakpointThreshold.WEB;

  const enteringPageAnimation = createAnimation()
    .addElement(enteringPageEl)
    // it is necessary to remove the 'ion-page-invisible' class, otherwise the new page is only visible after the
    // animation has finished. The class is automatically set by Ionic when the navigation starts.
    .beforeRemoveClass(['keep-visible', 'ion-page-invisible'])
    .beforeStyles({
      'z-index': Z_INDEX_BACKGROUND,
      transform: getBackgroundTransform({ isTabRoot: isEnteringPageTabRoot, isMobileLayout }),
      opacity: OPACITY_BACKGROUND,
    })
    .keyframes([
      { offset: 0, opacity: OPACITY_BACKGROUND },
      { offset: 0.3, opacity: OPACITY_ACTIVE },
      { offset: 1, opacity: OPACITY_ACTIVE },
    ])
    .fromTo('transform', getBackgroundTransform({ isTabRoot: isEnteringPageTabRoot, isMobileLayout }), TRANSFORM_ACTIVE)
    .fromTo('opacity', OPACITY_BACKGROUND, OPACITY_ACTIVE)
    .afterStyles({
      'z-index': Z_INDEX_ACTIVE,
      transform: TRANSFORM_ACTIVE,
      opacity: OPACITY_ACTIVE,
    });

  animation.addAnimation(enteringPageAnimation);

  if (leavingEl) {
    const leavingPageAnimation = createAnimation()
      .addElement(getIonPageElement(leavingEl))
      .beforeStyles({
        transform: TRANSFORM_ACTIVE,
        opacity: OPACITY_ACTIVE,
      })
      .fromTo('transform', TRANSFORM_ACTIVE, TRANSFORM_BEFORE_MOVE_UP)
      .fromTo('opacity', OPACITY_ACTIVE, OPACITY_INVISIBLE);

    animation.addAnimation(leavingPageAnimation);
  }

  if (!isNil(rootTransitionOpts)) {
    // all the pages between the leaving page and the root page of that stack need to be hidden immediately
    const { enteringElIndex, leavingElIndex, allIonPageElements } = rootTransitionOpts;

    const indicesToHide: number[] = range(enteringElIndex + 1, leavingElIndex);

    if (indicesToHide.length > 0) {
      const rootAnimation = createAnimation('additional-root-animation')
        .duration(0)
        .fromTo('opacity', OPACITY_BACKGROUND, OPACITY_INVISIBLE);

      indicesToHide.forEach((index: number) => !isNil(allIonPageElements[index]) && rootAnimation.addElement(allIonPageElements[index]));

      animation.addAnimation(rootAnimation);
    }
  }

  return animation;
}
