import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { environment } from '../environments/environment';

export const contentCreatorGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  if (!environment.CONTENT_CREATORS) {
    return inject(Router).createUrlTree(['/home'], { queryParams: route.queryParams });
  }

  return true;
};
