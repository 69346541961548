import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import * as UserActions from '@stsm/user/store/user.actions';

import * as TextbookActions from './textbooks.actions';

@Injectable()
export class TextbookEffects {
  protected clearTextbooksOnLogout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(UserActions.logout),
      map(() => TextbookActions.clearTextbooks()),
    ),
  );

  constructor(private readonly _actions$: Actions) {}
}
