import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';

import { TaskTrackingSource } from '@stsm/analytics';
import { OnboardingTasksBaseService } from '@stsm/feed';

@Injectable({ providedIn: 'root' })
export class OnboardingTasksService extends OnboardingTasksBaseService {
  override async createFlashcard(trackingSource: TaskTrackingSource): Promise<void> {
    const studyset = await this.getLastCreatedStudyset();

    if (!isNil(studyset)) {
      await this.navigationService.navigateToCreateFlashcard(studyset.id, { trackingSource });
    }
  }
}
