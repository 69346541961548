import { Update } from '@ngrx/entity';
import { isNil } from 'lodash-es';

import { UpdateBuilderBase } from '@stsm/shared/util/update-builder';

import { Studygroup, studygroupKeyMapping } from './studygroup';
import { StudygroupMember } from './studygroup-member';

export class StudygroupUpdateBuilder extends UpdateBuilderBase<Studygroup> {
  constructor(instance: Studygroup) {
    super(studygroupKeyMapping, instance, (studygroup: Studygroup) => studygroup.id);
  }

  get studygroup(): Studygroup | undefined {
    return this.instance;
  }

  get partialUpdate(): Update<Studygroup> {
    return this.update as Update<Studygroup>;
  }

  allowInviting(allowInviting: boolean): this {
    super.setKeyInBodyAndInstance('allowInviting', allowInviting);

    return this;
  }

  allowJoining(allowJoining: boolean): this {
    super.setKeyInBodyAndInstance('allowJoining', allowJoining);

    return this;
  }

  removeMember(memberId: number): this {
    if (!isNil(this.instance)) {
      super.setInInstance(
        'members',
        this.instance.members.filter((member: StudygroupMember) => member.id !== memberId),
      );
    }

    return this;
  }

  addPendingEmail(email: string): this {
    if (!isNil(this.instance)) {
      super.setInInstance('pendingEmails', [...this.instance.pendingEmails, email]);
    }

    return this;
  }

  removePendingEmail(email: string): this {
    if (!isNil(this.instance)) {
      super.setInInstance(
        'pendingEmails',
        this.instance.pendingEmails.filter((pendingMail: string) => pendingMail !== email),
      );
    }

    return this;
  }
}
