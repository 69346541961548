<ui-dialog-template showCloseButton uiThemed colorId="denim">
  <div class="header" slot="header">
    <h4>{{ "SHARE.SHARING_DIALOG.HEADER" | translate }}</h4>
  </div>

  <div class="content" slot="content">
    <div class="benefits">
      <ui-info-card hasIconBackground icon="app-icon-follow" [isCenteredLayout]="!isMobileLayout()">
        <ng-container slot="header">{{ "SHARE.SHARING_DIALOG.MOTIVATE_HEADER" | translate }}</ng-container>
        <ng-container slot="description">{{ "SHARE.SHARING_DIALOG.MOTIVATE_DESC" | translate }}</ng-container>
      </ui-info-card>

      <!-- Swap/Switch icon missing from icon-font -->
      <ui-info-card hasIconBackground icon="app-icon-group" [isCenteredLayout]="!isMobileLayout()">
        <ng-container slot="header">{{ "SHARE.SHARING_DIALOG.PERSPECTIVES_HEADER" | translate }}</ng-container>
        <ng-container slot="description">{{ "SHARE.SHARING_DIALOG.PERSPECTIVES_DESC" | translate }}</ng-container>
      </ui-info-card>

      <ui-info-card hasIconBackground icon="app-icon-share-android" [isCenteredLayout]="!isMobileLayout()">
        <ng-container slot="header">{{ "SHARE.SHARING_DIALOG.MATERIALS_HEADER" | translate }}</ng-container>
        <ng-container slot="description">{{ "SHARE.SHARING_DIALOG.MATERIALS_DESC" | translate }}</ng-container>
      </ui-info-card>
    </div>

    <div class="actions">
      <div class="friends-referred">
        <div class="info">
          <div class="text">{{ "SHARE.SHARING_DIALOG.FRIENDS_REFERRED" | translate }}</div>
          <ui-badge>{{ signupsGenerated }}</ui-badge>
        </div>
        <div class="description">{{ "SHARE.SHARING_DIALOG.FRIENDS_REFERRED_DESC" | translate }}</div>
      </div>

      <div class="buttons">
        <button uiButton secondary (click)="shareViaMail()">
          <i class="app-icon-mail" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.SHARE_EMAIL_BUTTON" | translate }}
        </button>

        <button uiButton (click)="copyShareLink()">
          <i class="app-icon-link" slot="icon"></i>{{ "SHARE.SHARING_DIALOG.COPY_LINK_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>
</ui-dialog-template>
