<ui-dialog-template [showCloseButton]="true" [heading]="'SHARE.MATERIAL_VISIBILITY.HEADER' | translate" (closeButtonClick)="closeDialog()">
  <div slot="content">
    <div class="disclaimer" [innerHTML]="'SHARE.MATERIAL_VISIBILITY.DESCRIPTION' | translate"></div>

    <ui-accordion>
      <ui-accordion-item [flat]="true" [expanded]="true" [toggleIconPosition]="'start'" [disabled]="slidesetIds().length === 0">
        <div class="item-header" uiAccordionItemHeader>
          <div>
            {{ "SHARE.MATERIAL_VISIBILITY.FILES" | translate }}
            <span class="item-count">{{ selectedSlidesets }}/{{ slidesetIds().length }}</span>
          </div>
          <button
            uiButton
            quarternary
            accent
            [disabled]="slidesetIds().length === 0"
            (click)="toggleSelection($event, form.controls.slidesets, slidesets())"
          >
            {{ (selectedSlidesets === 0 ? "SHARE.MATERIAL_VISIBILITY.SELECT_ALL" : "SHARE.MATERIAL_VISIBILITY.DESELECT_ALL") | translate }}
          </button>
        </div>

        @for (control of form.controls.slidesets.controls; track control; let i = $index) {
          @if (slidesetIds()[i]; as slidesetId) {
            @if (slidesetId | slidesetById | async; as slideset) {
              <app-file-item
                [fileName]="slideset.name"
                [fileType]="'pdf'"
                [updatedAt]="slideset.lastUsed"
                [isChecked]="control.value"
                (click)="control.setValue(!control.value)"
              />
            }
          }
        }
      </ui-accordion-item>

      <ui-accordion-item [flat]="true" [expanded]="true" [toggleIconPosition]="'start'" [disabled]="summaryIds().length === 0">
        <div class="item-header" uiAccordionItemHeader>
          <div>
            {{ "SHARE.MATERIAL_VISIBILITY.SUMMARIES" | translate }}
            <span class="item-count">{{ selectedSummaries }}/{{ summaryIds().length }}</span>
          </div>
          <button
            uiButton
            quarternary
            accent
            [disabled]="summaryIds().length === 0"
            (click)="toggleSelection($event, form.controls.summaries, summaries())"
          >
            {{ (selectedSummaries === 0 ? "SHARE.MATERIAL_VISIBILITY.SELECT_ALL" : "SHARE.MATERIAL_VISIBILITY.DESELECT_ALL") | translate }}
          </button>
        </div>

        @for (control of form.controls.summaries.controls; track control; let i = $index) {
          @if (summaryIds()[i]; as summaryId) {
            @if (summaryId | summaryById | async; as summary) {
              <app-file-item
                [fileName]="summary.name"
                [updatedAt]="summary.lastEditedAt"
                [isChecked]="control.value"
                (click)="control.setValue(!control.value)"
              />
            }
          }
        }
      </ui-accordion-item>
    </ui-accordion>
  </div>

  <footer slot="footer">
    <button data-cy="material-visibility-dialog-confirm-selection-button" uiButton (click)="confirmSelection()">
      {{ "SHARE.MATERIAL_VISIBILITY.CONFIRM_AND_PUBLISH" | translate }}
    </button>

    <div class="disclaimer">
      {{ "SHARE.MATERIAL_VISIBILITY.FOOTNOTE" | translate }}
    </div>
  </footer>
</ui-dialog-template>
