<nav appNavigation></nav>

<main>
  <!-- set host: { class: 'full-page-route' } on a routed page to use the full page -->
  <ion-router-outlet #outlet [swipeGesture]="false" [animation]="scaffoldContentTransitionAnimation" />
  <app-ai-assistant-button *ngIf="showAiAssistant()" />
</main>
<aside appSizeObserver data-cy="scaffold-aside" (sizeChange)="onSidebarSizeChange($event)">
  <app-enigma-sidebar *ngIf="isSidebarShown()" />
</aside>

<app-offline-disclaimer *ngIf="!isMobileApp" />
