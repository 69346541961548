import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/languages/fr.js';
import 'froala-editor/js/languages/it.js';
import 'froala-editor/js/languages/pt_pt.js';
import 'froala-editor/js/languages/tr.js';
import './froala.image-size';

import { ModuleWithProviders, NgModule } from '@angular/core';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';

import { addCustomIconsToFroala } from '../../shared/util/froala.util';

// relative paths are necessary for vite to resolve the modules
// https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-require-imports
(window as any).FroalaEditor = require('../../../../../../node_modules/froala-editor/js/froala_editor.min.js');

// Wiris requires FroalaEditor to exist on the window object
// eslint-disable-next-line @typescript-eslint/no-require-imports
require('../../../../../../node_modules/@wiris/mathtype-froala/wiris.js');

@NgModule()
export class FroalaModule {
  static forRoot(): ModuleWithProviders<FroalaEditorModule> {
    addCustomIconsToFroala();

    return {
      ngModule: FroalaEditorModule,
      providers: [],
    };
  }
}
