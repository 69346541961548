import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { map, of, switchMap, take } from 'rxjs';

import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';

import { DocumentViewerPageComponent } from './document-viewer-page.component';
import { DocumentViewerViewModel } from './document-viewer-store.service';

export const documentViewerCanDeactivateGuard: CanDeactivateFn<DocumentViewerPageComponent> = (component: DocumentViewerPageComponent) => {
  const simpleDialogService = inject(SimpleDialogService);

  return component.vm$.pipe(
    take(1),
    switchMap((vm: DocumentViewerViewModel) => {
      if (!vm.isGeneratingHighlightAnnotations) {
        return of(true);
      }

      return simpleDialogService
        .scheduleConfirm({
          heading: 'DOCUMENT_VIEWER.AUTO_HIGHLIGHT_NAVIGATION_WARNING.TITLE',
          text: 'DOCUMENT_VIEWER.AUTO_HIGHLIGHT_NAVIGATION_WARNING.DESC',
          confirmText: 'DOCUMENT_VIEWER.AUTO_HIGHLIGHT_NAVIGATION_WARNING.CONFIRM',
          cancelText: 'DOCUMENT_VIEWER.AUTO_HIGHLIGHT_NAVIGATION_WARNING.CANCEL',
          isDestructive: true,
        })
        .pipe(map(Boolean));
    }),
  );
};
