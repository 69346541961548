import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';

export const recaptchaV3SiteKeyFactory: (isProduction: boolean, defaultSiteKey: string) => string = (
  isProduction: boolean,
  defaultSiteKey: string,
) => {
  if (!isProduction) {
    const devToolsEnvironment = localStorage.getItem(GlobalLocalStorageKey.DEVTOOLS_ENVIRONMENT);
    const recaptchaDev = '6Le8UsoZAAAAAAA-67_-c6VaDia0hevBkPE2V8aH';
    const recaptchaProd = '6Ld8QK0UAAAAADzNrdSZWBzjAXlsxmBg4gHga-Z_';

    if (devToolsEnvironment) {
      return devToolsEnvironment === 'PROD' ? recaptchaProd : recaptchaDev;
    }

    return defaultSiteKey;
  }

  return defaultSiteKey;
};
