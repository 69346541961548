import { Injectable } from '@angular/core';

import { PropertiesOf } from '@stsm/shared/types/properties-of';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { ShareStudysetDialogComponent } from './share-studyset-dialog.component';

@Injectable({ providedIn: 'root' })
export class ShareStudysetDialogService {
  constructor(private readonly _platformModalService: PlatformModalService) {}

  openDialog(studysetId: number): void {
    this._platformModalService.create({
      component: ShareStudysetDialogComponent,
      data: <PropertiesOf<ShareStudysetDialogComponent>>{
        studysetId,
      },
      webOptions: {
        width: 400,
        minHeight: 'min(600px, 80vh)',
      },
      mobileOptions: {
        isAutoHeight: true,
      },
    });
  }
}
