import { inject } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { isNil } from 'lodash-es';
import { catchError, combineLatest, EMPTY, filter, pipe, retry, Subject, switchMap, tap, timer } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService, TimeTrackFeature } from '@stsm/analytics';
import { AuthStore } from '@stsm/auth/data/auth-store.service';
import { DurationInMilliseconds } from '@stsm/date/enums/duration-in-milliseconds';
import { DurationInSeconds } from '@stsm/date/enums/duration-in-seconds';
import { getElapsedTimeInSeconds } from '@stsm/date/functions/get-elapsed-time-in-seconds';
import { Milliseconds, Seconds } from '@stsm/date/util/time-util';
import { UsageTime } from '@stsm/global/models/usage-time';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { VOID } from '@stsm/shared/util/rxjs.util';

import { FEATURE_TIME_TRACKING_SERVICE } from '../tokens/feature-time-tracking-service.token';

import { FeatureTimeTrackingBaseService } from './feature-time-tracking-base.service';
import { UsageTimeService } from './usage-time.service';

const MILESTONES: Seconds[] = [
  5 * DurationInSeconds.MINUTE,
  10 * DurationInSeconds.MINUTE,
  15 * DurationInSeconds.MINUTE,
  20 * DurationInSeconds.MINUTE,
  30 * DurationInSeconds.MINUTE,
  40 * DurationInSeconds.MINUTE,
  50 * DurationInSeconds.MINUTE,
  60 * DurationInSeconds.MINUTE,
  90 * DurationInSeconds.MINUTE,
  120 * DurationInSeconds.MINUTE,
  150 * DurationInSeconds.MINUTE,
  180 * DurationInSeconds.MINUTE,
  4 * DurationInSeconds.HOUR,
  6 * DurationInSeconds.HOUR,
  8 * DurationInSeconds.HOUR,
  10 * DurationInSeconds.HOUR,
  15 * DurationInSeconds.HOUR,
  20 * DurationInSeconds.HOUR,
  25 * DurationInSeconds.HOUR,
  30 * DurationInSeconds.HOUR,
  40 * DurationInSeconds.HOUR,
  60 * DurationInSeconds.HOUR,
  80 * DurationInSeconds.HOUR,
  100 * DurationInSeconds.HOUR,
];

// maps the seconds to minutes for ease of use
const SINGULAR_MILESTONES: { [milestone: Seconds]: string } = {
  [10 * DurationInSeconds.MINUTE]: `10min`,
  [30 * DurationInSeconds.MINUTE]: `30min`,
  [60 * DurationInSeconds.MINUTE]: `60min`,
};

const TRACKING_INTERVAL: Milliseconds = 30 * DurationInMilliseconds.SECOND; // 30 seconds
const MINIMUM_TIMESLOT_SECONDS: Seconds = 2;

export interface TrackingData {
  studysetId: number | undefined;
  feature: TimeTrackFeature;
  startTime: Date;
}

export abstract class TimeTrackingBaseService {
  protected readonly trackingActive$: Subject<boolean> = new Subject<boolean>();

  protected readonly loggerService: LoggerService = inject(LoggerService);

  private _currentTrackingData: TrackingData | undefined;

  // overall usage time of user for triggering milestone events
  private _secondsTotal: number = -1;

  private readonly _analyticsService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);
  private readonly _authStore: AuthStore = inject(AuthStore);
  private readonly _usageTimeService: UsageTimeService = inject(UsageTimeService);
  private readonly _featureTimeTrackingService: FeatureTimeTrackingBaseService = inject(FEATURE_TIME_TRACKING_SERVICE);
  private readonly _routerStoreFacade: RouterStoreFacade = inject(RouterStoreFacade);

  protected constructor() {
    rxMethod(
      pipe(
        filter(Boolean),
        switchMap(() =>
          this._usageTimeService.getUsageTime().pipe(
            tap(({ secondsTotal }: UsageTime) => (this._secondsTotal = secondsTotal)),
            retry({ count: 1, delay: 3000 }),
            catchError((error: unknown) => {
              console.warn(error);

              return VOID;
            }),
          ),
        ),
      ),
    )(this._authStore.isUserLoggedIn$);

    combineLatest([this._authStore.isUserLoggedIn$, this.trackingActive$])
      .pipe(
        switchMap(([isUserLoggedIn, isTrackingActive]: [boolean, boolean]) => {
          this.loggerService.debug('#TT tracking active', isUserLoggedIn && isTrackingActive);

          if (!isUserLoggedIn) {
            // We cannot send any open timeslot once the user has logged out --> discard current tracking data
            this._currentTrackingData = undefined;

            return EMPTY;
          }

          if (!isTrackingActive) {
            this._handleTimeslot();

            this._currentTrackingData = undefined;

            return EMPTY;
          }

          return combineLatest([this._routerStoreFacade.studysetIdFromRoute$, this._featureTimeTrackingService.feature$]).pipe(
            switchMap(([studysetId, feature]: [number | undefined, TimeTrackFeature]) =>
              timer(0, TRACKING_INTERVAL).pipe(
                tap(() => {
                  this._handleTimeslot();

                  this._currentTrackingData = {
                    studysetId,
                    feature,
                    startTime: new Date(),
                  };
                }),
              ),
            ),
          );
        }),
      )
      .subscribe();
  }

  private _handleTimeslot(): void {
    if (isNil(this._currentTrackingData)) {
      return;
    }

    if (!this._authStore.isUserLoggedIn) {
      return;
    }

    const elapsedTime = getElapsedTimeInSeconds(this._currentTrackingData.startTime);

    if (elapsedTime < MINIMUM_TIMESLOT_SECONDS) {
      this.loggerService.debug(`#TT timeslot blocked: ${elapsedTime} < 2 seconds`);

      return;
    }

    this.postTimeslot({ ...this._currentTrackingData })
      .then((updatedSecondsTotal: number | undefined) => {
        if (!isNil(updatedSecondsTotal)) {
          this._checkTimeMilestones(updatedSecondsTotal);
        }
      })
      .catch((error: unknown) => {
        this.loggerService.warn(error);
      });
  }

  private _checkTimeMilestones(overallSeconds: number): void {
    if (this._secondsTotal === -1) {
      return;
    }

    // check if one of the milestones has been crossed - if so, trigger the respective event
    for (const milestone of MILESTONES) {
      if (this._secondsTotal < milestone && overallSeconds >= milestone) {
        // Send milestone event
        const milestoneString =
          milestone <= 180 * DurationInSeconds.MINUTE
            ? `${milestone / DurationInSeconds.MINUTE}min`
            : `${milestone / DurationInSeconds.HOUR}h`;
        this._analyticsService.trackEvent({ action: `timetracking_milestone_${milestoneString}` });

        if (SINGULAR_MILESTONES[milestone]) {
          this._analyticsService.trackSingularEvent(`timetracking-${SINGULAR_MILESTONES[milestone]}`);
        }

        break;
      }
    }
    this._secondsTotal = overallSeconds;
  }

  /**
   * returns the new total seconds or undefined (mobile offline)
   * @param trackingData
   */
  abstract postTimeslot(trackingData: TrackingData): Promise<number | undefined>;
}
