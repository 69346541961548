import { ActionReducerMap } from '@ngrx/store';

import { FlashcardsEffects } from '@stsm/flashcards/store';
import { SharedGlobalState, sharedReducers } from '@stsm/store/shared-global-state';
import { StudyplanEffects } from '@stsm/studyplan/store/studyplan.effects';
import { TaskEffects } from '@stsm/studyplan/store/tasks/task.effects';
import { TextbookEffects } from '@stsm/textbooks/store/textbook.effects';

import { StudysetEffects } from './effects/studyset.effects';
import { TagsEffects } from './effects/tags.effects';
import { UserEffects } from './effects/user.effects';

export type GlobalState = SharedGlobalState;

export const reducers: ActionReducerMap<GlobalState> = {
  ...sharedReducers,
};

export const effects = [UserEffects, StudysetEffects, FlashcardsEffects, TagsEffects, StudyplanEffects, TaskEffects, TextbookEffects];
