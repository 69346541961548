import { ExperimentFlag, NonDefaultExperimentValues } from '@stsm/analytics';
import { ObjectValue } from '@stsm/shared/types/object-value';

import { getDevtoolsInformationForExperimentOptions } from './devtools-splittest.util';

type ExperimentFlagToOptionsMapping = Record<ObjectValue<typeof ExperimentFlag>, { label: string; value: string }[]>;

export const EXPERIMENT_FLAG_TO_OPTIONS_MAPPING: ExperimentFlagToOptionsMapping = {
  ...Object.values(ExperimentFlag).reduce((acc: ExperimentFlagToOptionsMapping, key: ObjectValue<typeof ExperimentFlag>) => {
    acc[key] = getDevtoolsInformationForExperimentOptions(NonDefaultExperimentValues[key]);

    return acc;
  }, {} as ExperimentFlagToOptionsMapping),
};
