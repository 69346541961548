import { Ad } from '@stsm/global/models/ad';
import { LeadSubmitData } from '@stsm/global/models/lead-question';

export const LEAD_GEN_AD_MODAL_ID = 'lead-gen-ad-modal';

export enum LeadGenAdTrackingSource {
  REWARDED = 'rewarded',
  FEED = 'feed',
  FLASHCARD_PRACTICE = 'flashcard_practice',
  QUIZ = 'quiz',
  SPACED_REPETITION = 'spaced_repetition',
  FC_CREATION = 'fc_creation',
  AD_POPUP = 'ad_popup',
}

export interface OpenLeadGenAdData {
  ad: Ad;
  trackingSource?: string;
}

export interface LeadGenAdSubmitData {
  ad: Ad;
  data: LeadSubmitData;
}

export type LeadGenAdModalStep = 'company_details' | 'personal_info' | 'questionnaire' | 'entry_question';
