import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, Observable } from 'rxjs';

import { SystemMaintenanceStore } from '../services/system-maintenance-store.service';

// allow call to fetch system maintenance info and calls to local assets to load e.g. translation files etc.
const WHITELIST: string[] = ['assets', 'maintenance'];

/**
 * We cannot use the SystemMaintenanceService directly because it uses the HttpClient.
 * The HttpClient tries to inject the provided interceptors which causes a circular dependency
 * SystemMaintenanceService --> HttpClient --> HTTP_INTERCEPTORS --> SystemMaintenanceService
 * https://github.com/angular/angular/issues/24306#issuecomment-397357650
 */
@UntilDestroy()
@Injectable()
export class SystemMaintenanceInterceptor implements HttpInterceptor {
  private _isSystemMaintenanceOngoing: boolean = false;

  constructor(private readonly _systemMaintenanceStore: SystemMaintenanceStore) {
    this._systemMaintenanceStore.isSystemMaintenanceOngoing$.pipe(untilDestroyed(this)).subscribe((isSystemMaintenanceOngoing: boolean) => {
      this._isSystemMaintenanceOngoing = isSystemMaintenanceOngoing;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (WHITELIST.some((whiteListedUrl: string) => req.url.includes(whiteListedUrl))) {
      return next.handle(req);
    }

    return !this._isSystemMaintenanceOngoing ? next.handle(req) : EMPTY;
  }
}
